import React from "react";
import { Link } from "react-router-dom";

import {
  HOME_INFO,
  FOOTER_INFO
} from "constant/index";
import "components/Mobile/HomeComponents/CompanyFeature/CompanyFeature.css";

const CompanyFeature = () => {

  const CompanyFeature = () => {
    const {
      COMPANY_FEATURE_INGO: {
        COMPANY_FEATURE_CONTENT: { TITLE = "", DESC = "", LIST = [] },
      },
    } = HOME_INFO;

    return (
      <div className="mb-container card1">
        <div className="home-company-feature__title">{ TITLE }</div>
        <div className="home-company-feature__title-desc">{ DESC } </div>
        <div className="home-company-feature__list">
          { LIST.length &&
            LIST.map((item, index) => (
              <div className="home-company-feature__list-item" key={index}>
                <i
                  className="global__bg"
                  style={{
                    backgroundImage: `url(${item.ICON})`,
                  }}
                />
                <p>{ item.CONTENT }</p>
              </div>
            ))}
        </div>
      </div>
    )
  }
  const CompanyRecruit = () => {
    const {
      COMPANY_FEATURE_INGO: {
        COMPANY_RECRUIT_CONTENT: {
          TITLE = "",
          DESC = "",
          RECRUIT_LIST = [],
          STATUS = "",
        },
      },
    } = HOME_INFO;

    return (
      <div className="mb-container card2">
        <div className="home-company-feature__title">{ TITLE }</div>
        <div className="home-company-feature__title-desc">{ DESC } </div>
        <div className="home-company-feature__status">{ STATUS }</div>
        <div className="home-company-feature__list">
          { RECRUIT_LIST.map((item, index) => (
            <div className="home-company-feature__list-item" key={index}>
              <Link  to={item.URL}>
                <span className="paragraph_limit"> {item.NAME}</span>
                {item.ICON ? <img src={item.ICON} alt="" /> : null}
              </Link>
            </div>
          ))}
        </div>
      </div>
    )
  }
  const ContactUs = () => {
    const {
      CONTACT_US: { TITLE = "", SUB_TITLE = "", LIST = [] },
    } = FOOTER_INFO;

    return (
      <div className="mb-container card3">
        <div className="home-company-feature__title">{ TITLE }</div>
        <div className="home-company-feature__desc">{ SUB_TITLE }</div>
        <div className="home-company-features__list">
          { LIST.map((item, index) => (
            <div className="home-company-features__list-item" key={index}>
              <div className="home-company-features__list-item__icon">
                <img src={item.ICON} alt="" />
              </div>
              <div className="home-company-features__list-item-detail">
                <p>{ item.TITLE }</p>
                <span>{ item.DESC }</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <div className="home-company-feature">
      <CompanyFeature />
      <CompanyRecruit />
      <ContactUs />
    </div>
  )
}
export default CompanyFeature
