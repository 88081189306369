import LazyLoad from "react-lazyload";

import useTransitionTitle from "pages/Home/hooks/useTransitionTitle";
import { CSS_CLASS } from "../../../../constant";
import "./index.css";

const {
    FLEX,
} = CSS_CLASS;

export default function () {

    const {
        titleRef,
        titleStyle,
    } = useTransitionTitle();

    return (
        <div className="awards__container">
            <h2 ref={titleRef} style={titleStyle}>荣誉奖项，<span style={{ color: "#2b6cb0" }}>无惧挑战</span></h2>
            <p>过去的荣耀，未来的基石</p>
            <div className="awards__main">
                <LazyLoad>
                    <div className={`awards__main-row ${FLEX}`}>
                        {items[0].map(item => {
                            const {
                                label,
                                iconStyle,
                            } = item;

                            return (
                                <div key={label} style={{ flexBasis: 192 }}>
                                    <div className="awards__main-row-item-icon" style={iconStyle}></div>
                                    <p>{label}</p>
                                </div>
                            );
                        })}
                    </div>
                    <div className={`awards__main-row ${FLEX}`}>
                        {items[1].map(item => {
                            const {
                                label,
                                iconStyle,
                            } = item;

                            return (
                                <div key={label} style={{ flexBasis: 192 }}>
                                    <div className="awards__main-row-item-icon" style={iconStyle}></div>
                                    <p>{label}</p>
                                </div>
                            );
                        })}
                    </div>
                </LazyLoad>
            </div>
        </div>
    );
}

const items = [
    [
        {
            label: "第十二届中国创新创业大赛（成长组）优秀企业",
            iconStyle: {
                width: 48,
                height: 48,
                background: "url(\"https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/awards/1.png\") center/cover no-repeat",
            },
        },
        {
            label: "第七届光明区创新创业大赛行业赛一等奖",
            iconStyle: {
                width: 88,
                height: 48,
                background: "url(\"https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/awards/2.png\") center/cover no-repeat",
            },
        },
        {
            label: "第七届光明区创新创业大赛新一代信息技术冠军",
            iconStyle: {
                width: 54,
                height: 48,
                background: "url(\"https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/awards/3.png\") center/cover no-repeat",
            },
        },
        {
            label: "第十五届中国深圳创新创业大赛行业决赛优秀奖",
            iconStyle: {
                width: 72,
                height: 48,
                background: "url(\"https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/awards/4.png\") center/cover no-repeat",
            },
        },
    ],
    [
        {
            label: "2023港科大百万奖金国际创业大赛（深圳）冠军",
            iconStyle: {
                width: 48,
                height: 48,
                background: "url(\"https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/awards/5.png\") center/cover no-repeat",
            },
        },
        {
            label: "2023港科大百万奖金国际创业大赛（深圳）最具投资价值奖",
            iconStyle: {
                width: 48,
                height: 48,
                background: "url(\"https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/awards/6.svg\") center/cover no-repeat",
            },
        },
        {
            label: "2023“科创中国”创新创业投资大会大会百强",
            iconStyle: {
                width: 48,
                height: 48,
                background: "url(\"https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/awards/7.png\") center/cover no-repeat",
            },
        },
        {
            label: "“科创中国”创新创业投资大会新一代信息技术TOP5",
            iconStyle: {
                width: 66,
                height: 48,
                background: "url(\"https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/awards/8.png\") center/cover no-repeat",
            },
        },
    ],
];
