import React from "react";

import { ABOUT_US_INFO } from "constant";
import "components/Pc/AboutUsComponents/AboutUsBanner/AboutUsBanner.css";

const AboutUsBanner = () => {
  const {
    ABOUT_US_BANNRE_TITLE = "",
    ABOUT_US_BANNER_BACKGROUND_IMG = "",
    ABOUT_US_BANNER_DESC = "",
    ABOUT_US_BANNER_DESC2 = "",
    ABOUT_US_BANNER_CELL_BACKGROUND_IMG = "",
    ABOUT_US_BANNER_IMG = "",
    // ABOUT_US_BANNER_LIST = [],
  } = ABOUT_US_INFO;
  
  return (
    <div className="about-us__page-banner">
      <div
        className="about-us__page-banner__top global__bg d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${ABOUT_US_BANNER_BACKGROUND_IMG})`,
        }}
      >
        <span>{ ABOUT_US_BANNRE_TITLE }</span>
      </div>
      <div className="about-us__page-banner__bottom">
        <div className="container">
          <div
            className="about-us__page-banner__bottom-content global__bg d-flex justify-content-between"
            style={{
              backgroundImage: `url(${ABOUT_US_BANNER_CELL_BACKGROUND_IMG})`,
            }}
          >
            <div className="about-us__page-banner__bottom-content-left">
              <div className="about-us__page-banner__bottom-content-left__desc">
                { ABOUT_US_BANNER_DESC }
              </div>
              <div className="about-us__page-banner__bottom-content-left__desc">
                { ABOUT_US_BANNER_DESC2 }
              </div>
              {/* <div className="about-us__page-banner__bottom-content-left__list d-flex">
                {ABOUT_US_BANNER_LIST.map((item, index) => (
                  <div
                    className="about-us__page-banner__bottom-content-left__item d-flex flex-column"
                    key={index}
                  >
                    <span>{item.RATIO}</span>
                    <span>{item.TITLE}</span>
                  </div>
                ))}
              </div> */}
            </div>
            <div className="about-us__page-banner__bottom-content-right">
              <div className="about-us__page-banner__bottom-content-right_img full-box">
                <img src={ABOUT_US_BANNER_IMG} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AboutUsBanner
