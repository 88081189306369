import { useCallback, useEffect, useMemo, useState } from "react";
import LazyLoad from "react-lazyload";

import { CSS_CLASS } from "../../../../constant";
import "./index.css";
import { config } from 'utils/config';
import useTransitionTitle from "pages/Home/hooks/useTransitionTitle";

const { homeImgUrl } = config;
const url = `${homeImgUrl}/projectResults/`;

const ITEM_WIDTH = 527; // width(content-box) + gap(flex)
const SWITCH_INTERVAL = 5000; // 轮播图切换时间间隔

const {
    INLINE_FLEX,
    FLEX,
    ALIGN_END,
    ALIGN_CENTER,
} = CSS_CLASS;

function PictureButton({
    isActive = false,
    activeImg,
    normalImg,
    width = 225,
    height = 64,
    style,
    onClick = () => { },
    ...buttonProps
}) {
    return (
        <div
            className="project-results__icon-container"
            style={{
                width,
                height,
                ...style,
            }}
        >
            <button
                style={{
                    width,
                    height,
                    ...style,
                }}
                onClick={onClick}
                {...buttonProps}
            ></button>
            <LazyLoad offset={100}>
                <img
                    className={`project-results__icon ${isActive ? "active" : ""}`}
                    style={{
                        width,
                        height,
                    }}
                    src={activeImg}
                    alt=""
                />
            </LazyLoad>
            <LazyLoad offset={100}>
                <img
                    className={`project-results__icon ${isActive ? "" : "active"}`}
                    style={{
                        width,
                        height,
                    }}
                    src={normalImg}
                    alt=""
                />
            </LazyLoad>
        </div>
    );
}

export default function () {

    const [activeIndex, setActiveIndex] = useState(2);
    const [cardListStyle, setCardListStyle] = useState({
        transform: `translateX(-${ITEM_WIDTH}px)`,
    });
    const [cardStyle, setCardStyle] = useState({
        transition: "transform 0.5s linear",
    });
    const [isTransitionProcessing, setIsTransitionProcessing] = useState(false);

    const items = useMemo(() => {
        // 前后各添加两个克隆节点，用于实现循环滚动
        const lastIndex = content.length - 1;
        return [content[lastIndex - 1], content[lastIndex], ...content, content[0], content[1]];
    }, []);

    // 切换到指定item
    const handleItemSwitch = useCallback((targetItemIndex) => {
        if (isTransitionProcessing || activeIndex === targetItemIndex) {
            // 仅当无正在播放的transition且点击了别的item时触发动画
            return;
        }
        const targetTranslateX = (targetItemIndex - 1) * ITEM_WIDTH;
        setActiveIndex(targetItemIndex);
        setCardStyle({
            transition: "transform 0.5s linear",
        });
        setCardListStyle({
            transition: "transform 0.5s linear",
            transform: `translateX(-${targetTranslateX}px)`,
        });
        setIsTransitionProcessing(true);
    }, [activeIndex, isTransitionProcessing]);

    function handleTransitionEnd() {
        // 保持视口在items中间部分
        const style = {
            transition: "none",
        };
        let translateX = (activeIndex - 1) * ITEM_WIDTH,
            finalActiveIndex = activeIndex;
        if (activeIndex >= content.length + 2) {
            // 已经滚动到下一轮
            translateX -= ITEM_WIDTH * content.length;
            finalActiveIndex -= content.length;
        } else if (activeIndex < 2) {
            // 已经滚动到上一轮
            translateX += content.length * ITEM_WIDTH;
            finalActiveIndex += content.length;
        }
        style.transform = `translateX(-${translateX}px)`;
        setCardListStyle(style);
        setActiveIndex(finalActiveIndex);
        setCardStyle({
            transition: "none",
        });
        setIsTransitionProcessing(false);
    }

    // 定时切换轮播图, 主动切换时重置定时器
    useEffect(() => {
        if (!isTransitionProcessing) {
            function switchItem() {
                handleItemSwitch(activeIndex + 1);
            }
            const timerId = setTimeout(switchItem, SWITCH_INTERVAL);
            return () => {
                clearTimeout(timerId);
            };
        }
    }, [activeIndex, handleItemSwitch, isTransitionProcessing]);

    // 首次滚入时标题淡入
    const {
        titleRef,
        titleStyle,
    } = useTransitionTitle();

    return (
        <div className="project-results__container">
            <h3 ref={titleRef} style={titleStyle}>渐近线已助力各行业<strong>智能化转型</strong></h3>
            <div className="project-results__scroll-container">
                <LazyLoad offset={100}>
                    <div
                        className={`project-results__card-list ${FLEX}`}
                        style={cardListStyle}
                        onTransitionEnd={handleTransitionEnd}
                    >
                        {items.map((item, index) => {
                            const isActive = index === activeIndex;

                            return cardItemRender({
                                ...item,
                                index,
                                isActive,
                                onItemSwitch: handleItemSwitch,
                                cardStyle,
                            });
                        })}
                    </div>
                </LazyLoad>
            </div>
            <div className={`project-results__icon-list ${INLINE_FLEX}`}>
                {content.map((item, index) => {
                    const {
                        activeImg,
                        normalImg,
                        title,
                    } = item;
                    const isActive = items[activeIndex].title === title;
                    function handleIconClick() {
                        handleItemSwitch(index + 2);
                    }
                    return (
                        <PictureButton
                            activeImg={activeImg}
                            normalImg={normalImg}
                            isActive={isActive}
                            key={index}
                            onClick={handleIconClick}
                        />
                    );
                })}
            </div>
        </div>
    );
}

function cardItemRender({
    index,
    cardClassName,
    title,
    subTitle,
    description,
    quantificationData,
    isActive,
    onItemSwitch,
    cardStyle,
}) {

    return (
        <div
            key={index}
            style={cardStyle}
            className={`project-results__card ${cardClassName} ${FLEX} ${ALIGN_END} ${isActive ? "active" : ""}`}
            onClick={() => onItemSwitch(index)}
        >
            <div className="project-results__card-content">
                <div className={`${FLEX} ${ALIGN_CENTER}`} style={{ gap: 12, }}>
                    <div className="project-results__card-title">{title}</div>
                    <div className="title-separator"></div>
                    <div className="project-results__card-sub-title">{subTitle}</div>
                </div>
                <div className="description">
                    <div>{description}</div>
                </div>
                <div className={`${FLEX}`}>
                    {quantificationData.map((data) => {
                        const {
                            label,
                            value,
                        } = data;
                        return (
                            <div className="quantification-data-container" key={label}>
                                <div className="quantification-data-content">{value}</div>
                                <div className={`quantification-data-label ${FLEX}  ${ALIGN_CENTER}`}>{label}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

const content = [
    {
        cardClassName: "casc",
        activeImg: `${url}CASC-active.png`,
        normalImg: `${url}CASC-normal.png`,
        title: "中国航天",
        subTitle: "业务数字化转型全覆盖",
        description: "渐近线助力中国航天8359所实现精准的装配过程数据自动化记录采集、数据采集层离线式交互、智能装配产线多作业单元专业并行协同，推动兵工数字化转型升级。",
        quantificationData: [
            {
                label: "产能提升",
                value: "22%+",
            },
            {
                label: "装配时间降低",
                value: "37%",
            },
        ],
    },
    {
        cardClassName: "casic",
        activeImg: `${url}CASIC-active.png`,
        normalImg: `${url}CASIC-normal.png`,
        title: "军工装配",
        subTitle: "打造国内军工行业首条脉动式生产线",
        description: "渐近线助力某部门打造了国内军工行业首条脉动式总装调生产线，替代了传统的人工管理模式并结合生产全链路数字孪生可视化，强力支撑军工装配的智慧化转型。",
        quantificationData: [
            {
                label: "效率提升",
                value: "34%+",
            },
            {
                label: "人员成本降低",
                value: "46%",
            },
        ],
    },
    {
        cardClassName: "eikto",
        activeImg: `${url}EIKTO-active.png`,
        normalImg: `${url}EIKTO-normal.png`,
        title: "益佳通",
        subTitle: "解决动力电池共线生产难题",
        description: "渐近线助力益佳通实现动力电池后段产线的多类型产品混合生产复杂调度，通过准确的多类型产品混合生产、灵活快速的工艺调整响应以满足工业4.0时代的高效开发。",
        quantificationData: [
            {
                label: "产能提升",
                value: "31%+",
            },
            {
                label: "人工成本降低",
                value: "52%",
            },
            {
                label: "异常报错降低",
                value: "84%",
            },
        ],
    },
    {
        cardClassName: "llt",
        activeImg: `${url}LLT-active.png`,
        normalImg: `${url}LLT-normal.png`,
        title: "玲珑轮胎",
        subTitle: "赋能仓储流程高度自动化无缝流转",
        description: "渐近线助力玲珑轮胎欧洲-塞尔维亚仓储工厂打造多类型、多品牌设备的上下游混合调度，携手大步迈向无人自动化工厂的总目标，打造玲珑轮胎的国际战略化布局。",
        quantificationData: [
            {
                label: "配送效率提升",
                value: "28%",
            },
            {
                label: "人工参与降低",
                value: "36%",
            },
            {
                label: "生产效率提升",
                value: "33%",
            },
        ],
    },
    {
        cardClassName: "byd",
        activeImg: `${url}BYD-active.png`,
        normalImg: `${url}BYD-normal.png`,
        title: "比亚迪",
        subTitle: "搭建高效分拣、紧密流转的大型仓储系统",
        description: "渐近线助力比亚迪电机工厂打造对接大型企业集团的标准仓储系统，配合精准任务分配、动态调整调度、快速研发部署，弹性适配智能制造带来的业务飞速增长。",
        quantificationData: [
            {
                label: "开发周期缩短",
                value: "53%",
            },
            {
                label: "测试及部署周期缩短",
                value: "47%+",
            },
        ],
    },
];