import React from "react";

import "components/Mobile/AboutUsComponents/PhotoWall/PhotoWall.css";
import { ABOUT_US_INFO } from "constant";

const PhotoWall = () => {

  const {
    ABOUT_US_PHOTO_WALL_TITLE = "",
    MOBILE_ABOUT_US_PHOTO_WALL_BACKGROUND_IMG = "",
    MOBILE_ABOUT_US_PHOTO_WALL_IMG01 = "",
    MOBILE_ABOUT_US_PHOTO_WALL_IMG02 = "",
    MOBILE_ABOUT_US_PHOTO_WALL_IMG03 = "",
    MOBILE_ABOUT_US_PHOTO_WALL_IMG04 = "",
  } = ABOUT_US_INFO;
  
  return (
    <div className="about-us__photo-wall">
      <div
        className="top global__bg"
        style={{
          backgroundImage: `url(${MOBILE_ABOUT_US_PHOTO_WALL_BACKGROUND_IMG})`,
        }}
      >
        <div>{ ABOUT_US_PHOTO_WALL_TITLE }</div>
      </div>
      <div className="bottom">
        <div className="mb-container">
          <div
            className="bottom-pic01 global__bg"
            style={{
              backgroundImage: `url(${MOBILE_ABOUT_US_PHOTO_WALL_IMG01})`,
            }}
          />
          <div
            className="bottom-pic02 global__bg"
            style={{
              backgroundImage: `url(${MOBILE_ABOUT_US_PHOTO_WALL_IMG02})`,
            }}
          />
          <div
            className="bottom-pic03 global__bg"
            style={{
              backgroundImage: `url(${MOBILE_ABOUT_US_PHOTO_WALL_IMG03})`,
            }}
          />
          <div
            className="bottom-pic04 global__bg"
            style={{
              backgroundImage: `url(${MOBILE_ABOUT_US_PHOTO_WALL_IMG04})`,
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default PhotoWall
