import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import { HashRouter } from 'react-router-dom';

import App from 'App';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'common.css';

ReactDOM.render(
  <HashRouter >
    <App />
  </HashRouter>,
  document.getElementById('root')
);
reportWebVitals();
