import asymptoteIcon from "../../assets/images/asymptote-icon.svg";
import asymptoteName from "../../assets/images/asymptote-name.svg";
import { CSS_CLASS } from '../../constant';
import SectionOne from './components/SectionOne';
import ServiceRange from './components/ServiceRange';
import ResultOverview from './components/ResultOverview';
import ProjectResults from './components/ProjectResults';
import PartnerShow from './components/PartnerShow';
import Certificates from './components/Certificates';
import Awards from './components/Awards';
import BottomInfo from './components/BottomInfo';
import './index.css';
import ProductIntroduction from "./components/ProductIntroduction";

const {
    FLEX,
    ALIGN_CENTER,
} = CSS_CLASS;

function Header({

}) {

    const navigationItems = [
        {
            label: "首页",
            href: "#/",
        },
        {
            label: "产品服务",
            href: "#/products",
        },
        {
            label: "客户案例",
            href: "#/",
        },
        {
            label: "关于我们",
            href: "#/about",
        },
        {
            label: "加入我们",
            href: "#/jobs",
        },
    ];

    return (
        <div className={`header__container`}>
            <div className={`header__content ${FLEX} ${ALIGN_CENTER}`}>
                {/* icon */}
                <img style={{ marginRight: 8 }} src={asymptoteIcon} alt="" />
                <img src={asymptoteName} alt="" />
                {/* navigation-items */}
                <div className={`header__navigation-list ${FLEX} ${ALIGN_CENTER}`}>
                    {navigationItems.map(item => {
                        const {
                            label,
                            href,
                        } = item;

                        return (
                            <a key={label} href={href}>{label}</a>
                        );
                    })}
                </div>
            </div>

        </div>
    );

}

function Footer() {
    return (
        <div className={`footer__container ${FLEX}`}>
            <div style={{ marginLeft: "auto", marginRight: 40 }}>Copyright© 深圳市渐近线科技有限公司</div>
            <div>粤ICP备2021054396号-1</div>
            <div style={{ marginLeft: 36, marginRight: 20 }}>粤公网安备</div>
            <div style={{ marginRight: "auto" }}>11010702001922号</div>
        </div>
    );
}

function Home() {

    return (
        <div className="asymptote-home__container">
            {/* <Header /> */}
            <SectionOne />
            <ServiceRange />
            <ResultOverview />
            <ProjectResults />
            <ProductIntroduction />
            <PartnerShow />
            <Certificates />
            <Awards />
            <BottomInfo />
            <Footer />
        </div>
    );
}

export default Home;
