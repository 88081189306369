import React, { Component } from 'react';

import PcAboutUsBanner from 'components/Pc/AboutUsComponents/AboutUsBanner/AboutUsBanner';
import PcCompanyTeam from 'components/Pc/AboutUsComponents/CompanyTeam/CompanyTeam';
import PcPhotoWall from 'components/Pc/AboutUsComponents/PhotoWall/PhotoWall';
// import PcNewsInformation from "components/Pc/AboutUsComponents/NewsInformation/NewsInformation";
import MobileAboutUsBanner from 'components/Mobile/AboutUsComponents/AboutUsBanner/AboutUsBanner';
import MobileTeamLeader from 'components/Mobile/AboutUsComponents/TeamLeader/TeamLeader';
import MobilePhotoWall from 'components/Mobile/AboutUsComponents/PhotoWall/PhotoWall';
// import MobileNewsInformation from "components/Mobile/AboutUsComponents/NewsInformation/NewsInformation";

class AboutUsPage extends Component {
  render() {
    const { mobileDevice } = this.props;
    return (
      <div className="about-us__page">
        {!mobileDevice ? (
          <>
            <PcAboutUsBanner />
            <PcCompanyTeam />
            <PcPhotoWall />
            {/* <PcNewsInformation /> */}
          </>
        ) : (
          <>
            <MobileAboutUsBanner />
            <MobileTeamLeader />
            <MobilePhotoWall />
            {/* <MobileNewsInformation /> */}
          </>
        )}
      </div>
    );
  }
}
export default AboutUsPage;
