import React from "react";

import { ABOUT_US_INFO } from "constant";
import "components/Pc/AboutUsComponents/PhotoWall/PhotoWall.css";

const PhotoWall = () => {
  
  const {
    ABOUT_US_PHOTO_WALL_TITLE = "",
    ABOUT_US_PHOTO_WALL_BACKGROUND_IMGTO = "",
    ABOUT_US_PHOTO_WALL_IMG01 = "",
    ABOUT_US_PHOTO_WALL_IMG02 = "",
    ABOUT_US_PHOTO_WALL_IMG03 = "",
    ABOUT_US_PHOTO_WALL_IMG04 = "",
  } = ABOUT_US_INFO;

  return (
    <div className="about-us__photo-wall full-page d-flex flex-column">
      <div
        className="about-us__photo-wall-top global__bg d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${ABOUT_US_PHOTO_WALL_BACKGROUND_IMGTO})`,
        }}
      >
        <span>{ ABOUT_US_PHOTO_WALL_TITLE }</span>
      </div>
      <div className="about-us__photo-wall-bottom">
        <div className="container">
          <div
            className="about-us__photo-wall-bottom__pic01 global__bg"
            style={{
              backgroundImage: `url(${ABOUT_US_PHOTO_WALL_IMG01})`,
            }}
          />
          <div
            className="about-us__photo-wall-bottom__pic02 global__bg"
            style={{
              backgroundImage: `url(${ABOUT_US_PHOTO_WALL_IMG02})`,
            }}
          />
          <div
            className="about-us__photo-wall-bottom__pic03 global__bg"
            style={{
              backgroundImage: `url(${ABOUT_US_PHOTO_WALL_IMG03})`,
            }}
          />
          <div
            className="about-us__photo-wall-bottom__pic04 global__bg"
            style={{
              backgroundImage: `url(${ABOUT_US_PHOTO_WALL_IMG04})`,
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default PhotoWall
