import { useEffect } from "react";

// 特定DOM节点滚动至视口内时执行某些操作
export default function useScrollIntoViewPortObserver({
    targetNodeRef,
    threshold = 0.25,
    onlyOnce = true,
    onScrollIntoViewPort = () => { },
    onScrollOutOfViewPort = () => { },
    clear = () => { },
}) {

    useEffect(() => {
        if (!targetNodeRef?.current) {
            return;
        }

        function handleIntersection(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    onScrollIntoViewPort();
                    onlyOnce && observer.unobserve(targetNodeRef?.current);
                } else {
                    onScrollOutOfViewPort();
                }
            });
        }
        const observer = new IntersectionObserver(handleIntersection, {
            threshold,
        });
        observer.observe(targetNodeRef?.current);
        
        return () => {
            observer.disconnect();
            clear();
        };
    }, [targetNodeRef?.current, threshold, onlyOnce, onScrollIntoViewPort, onScrollOutOfViewPort, clear]);

}