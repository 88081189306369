import React from 'react';
import { renderRoutes } from 'react-router-config';

import PcHeader from 'components/Pc/Header/Header';
import PcFooter from 'components/Pc/Footer/Footer';

import MobileHeader from 'components/Mobile/Header/Header';
import MobileFooter from 'components/Mobile/Footer/Footer';

class SecurityLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileDevice: false
    };
  }

  resizeTimer = null;

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
  onResize = () => {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = null;
    }
    this.resizeTimer = setTimeout(() => {
      const mobileDevice = /Mobile/i.test(navigator.userAgent);
      const bodyTag = document.getElementsByTagName('body')[0];
      if (mobileDevice) {
        bodyTag.classList.remove('min');
      } else {
        bodyTag.classList.add('min');
      }
      this.setState({
        mobileDevice
      });
    }, 10);
  };
  render() {
    const { mobileDevice } = this.state;
    const {
      route,
      location: { pathname }
    } = this.props;
    const isHome = pathname === "/";
    return (
      <>
        {!mobileDevice ? (
          <PcHeader route={route.routes} pathname={pathname} />
          // <>
          //   {isHome ? (
          //     <></>
          //   ) : (
          //     <PcHeader route={route.routes} pathname={pathname} />
          //   )}
          // </>
        ) : (
          <MobileHeader route={route.routes} pathname={pathname} />
        )}
        {renderRoutes(route.routes, { mobileDevice })}
        {!mobileDevice ? (
          <>
            {isHome ? (
              <></>
            ) : (
              <PcFooter />
            )}
          </>
        ) : <MobileFooter />}
      </>
    );
  }
}
export default SecurityLayout;
