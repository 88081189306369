import React from "react";

import "components/Mobile/ProductServicesComponent/ProductServiceDetail/ProductServiceDetail.css";

const ProductServiceDetail = (props) => {
  
  const {
    item: {
      CH_TITLE = "",
      EN_TITLE = "",
      DESC = "",
      DETAIL = "",
      URL_2X = "",
      MOBILE_ICON = "",
    },
  } = props;

  return (
    <div className="product-service__detail-item">
      <div className="mb-container">
        <div className="product-service__detail-title">{ CH_TITLE }</div>
        <div className="product-service__detail-title__desc">
          <i
            className="global__bg"
            style={{
              backgroundImage: `url(${MOBILE_ICON})`,
            }}
          />
          <span className="en-title">{ EN_TITLE }</span>
          <span className="desc">{ DESC }</span>
        </div>
        <div className="product-service__detail-item__pic">
          <img src={URL_2X} alt="" />
          <div className="product-service__detail-item__pic-title">{ EN_TITLE }</div>
          <div className="product-service__detail-item__pic-cover" />
        </div>
        <div className="product-service__detail-item__content">{ DETAIL }</div>
      </div>
    </div>
  )
}
export default ProductServiceDetail
