import React, { Component } from "react"

import { COMPANY_PUBLIC_INFO } from "constant"
import "components/Pager/Pager.css"

class Pager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: props.current || 1, // 当前页码数
      btnIndexList: [] /* 要显示的页码序列 */,
      maxBtnSize: props.maxBtnSize || 5, // 中间按钮最大个数
      showPreAndNextMaxSize: props.showPreAndNextMaxSize || 10, // 是否显示上，下页按钮
      totalPage: 0,
    }
  }
  componentDidMount() {
    const { total = 0, pageSize } = this.props
    const { current } = this.state
    this.getBtnIndexList(current, pageSize, total);
  }
  getBtnIndexList = (current, pageSize, total) => {
    const { maxBtnSize, showPreAndNextMaxSize } = this.state
    const { onChange = () => {} } = this.props
    const totalPage = Math.ceil(total / pageSize)
    let btnIndexList = []
    if (totalPage < showPreAndNextMaxSize) {
      btnIndexList = [...Array.from({ length: totalPage }, (_, i) => i + 1)]
    } else {
      if (current <= maxBtnSize) {
        btnIndexList = [
          ...Array.from({ length: maxBtnSize + 1 }, (_, i) => i + 1),
          "•••",
          totalPage,
        ]
      } else if (current >= totalPage - maxBtnSize) {
        btnIndexList = [
          1,
          "•••",
          ...Array.from(
            { length: maxBtnSize + 1 },
            (_, i) => totalPage - maxBtnSize + i
          ),
        ]
      } else {
        btnIndexList = [
          1,
          "•••",
          ...Array.from(
            { length: maxBtnSize },
            (_, i) => current - Math.floor(maxBtnSize / 2) + i
          ),
          "•••",
          totalPage,
        ]
      }
    }
    this.setState(
      {
        btnIndexList,
        totalPage,
      },
      () => {
        onChange({ current, pageSize })
      }
    )
  }
  // 上一页
  goPrePage = () => {
    let { current } = this.state
    const { total = 0, pageSize } = this.props
    if (--current === 0) {
      return
    }
    this.getBtnIndexList(current, pageSize, total)
    this.setState({
      current,
    })
  }
  // 下一页
  goNextPage = () => {
    let { current, totalPage } = this.state
    const { total = 0, pageSize } = this.props
    if (++current > totalPage) {
      return
    }
    this.getBtnIndexList(current, pageSize, total)
    this.setState({
      current,
    })
  }
  handleJumpPage = (current) => {
    this.setState(
      {
        current,
      },
      () => {
        const { total = 0, pageSize } = this.props
        this.getBtnIndexList(current, pageSize, total)
      }
    )
  }
  onEllipsisHandler = (index) => {
    const { maxBtnSize, current } = this.state
    const { total, pageSize } = this.props
    // 左边省略号
    if (index === 1) {
      this.getBtnIndexList(current - maxBtnSize, pageSize, total)
      this.setState({
        current: current - maxBtnSize,
      })
    } else {
      // 右边省略号
      this.getBtnIndexList(current + maxBtnSize, pageSize, total)
      this.setState({
        current: maxBtnSize + current,
      })
    }
  }
  render() {
    const { btnIndexList, current } = this.state
    const { prevRenderNode, rightRenderNode } = this.props
    const { PAGER_LEFT_DEFAULT, PAGER_RIGHT_DEFAULT } = COMPANY_PUBLIC_INFO
    return (
      <ul className="pager" ref={(r) => (this.pagerRef = r)}>
        <li onClick={this.goPrePage}>
          {prevRenderNode ? (
            prevRenderNode
          ) : (
            <i
              className="global__bg"
              style={{
                backgroundImage: `url(${PAGER_LEFT_DEFAULT})`,
              }}
            />
          )}
        </li>
        {btnIndexList.map((item, index) => {
          if (typeof item === "number") {
            return (
              <li
                key={index}
                onClick={() => this.handleJumpPage(item)}
                className={current === item ? "active" : ""}
              >
                { item }
              </li>
            )
          } else {
            return (
              <li key={index} onClick={() => this.onEllipsisHandler(index)}>
                {item}
              </li>
            )
          }
        })}
        <li onClick={this.goNextPage}>
          {rightRenderNode ? (
            rightRenderNode
          ) : (
            <i
              className="global__bg"
              style={{
                backgroundImage: `url(${PAGER_RIGHT_DEFAULT})`,
              }}
            />
          )}
        </li>
      </ul>
    )
  }
}
export default Pager
