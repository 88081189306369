import React, { useEffect } from 'react';
import { 
  renderRoutes, 
  matchRoutes
} from "react-router-config";

const InitializeLayouts = (props) => {
  const { route, location } = props;
  
  useEffect(()=>{
    const branch = matchRoutes(route.routes, location.pathname);
    if(branch.length > 0){
      const title = branch[branch.length-1].route.meta?.title || '';
      document.title = `${title} - 渐近线`;
      window.scrollTo(0,0);
    }
  }, [route, location])
  
  return <>{ renderRoutes(route.routes) }</>;
};

export default InitializeLayouts;
