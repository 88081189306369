import { useEffect, useRef, useState } from "react";
import LazyLoad from "react-lazyload";

import { config } from 'utils/config';
import { CSS_CLASS } from "../../../../constant";
import "./index.css";

const { imgUrl } = config;
const {
    FLEX,
    ALIGN_CENTER,
} = CSS_CLASS;

export default function () {

    const containerRef = useRef(null);

    const [currentContentIndex, setCurrentContentIndex] = useState(0);

    useEffect(() => {
        let isAvailable = true,
            timerId = 0;
        function handleScroll() {
            if (!isAvailable) {
                // 节流
                return;
            }
            const {
                offsetTop,
                clientHeight,
            } = containerRef.current;
            const targetIndex = Math.min(3, Math.floor(offsetTop / (clientHeight / 3)));
            setCurrentContentIndex(targetIndex);
            isAvailable = false;
            timerId = setTimeout(() => {
                isAvailable = true;
            }, 100);
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            clearTimeout(timerId);
        };
    }, []);

    const {
        video,
        trait,
        name,
        abbr,
        details,
    } = content[currentContentIndex];

    return (
        <div
            className="product-introduction__container"
            style={{
                overflow: "visible",
            }}
        >
            <div className="product-introduction__content-container" ref={containerRef}>
                <div className="product-introduction__content-container-mask"></div>
                <div style={{ position: "relative", width: 1900, margin: "0 auto", height: "100%" }}>
                    <div className="product-introduction__content-info-container">
                        <div className="product-introduction__trait">{trait}</div>
                        <div className="product-introduction__name">{name}</div>
                        <div className="product-introduction__abbr">{abbr}</div>
                        {details.map((detail, index) => {
                            const {
                                title,
                                content,
                            } = detail;
                            return (
                                <div key={index} className="product-introduction__detail-item">
                                    <h3>{title}</h3>
                                    <p className={`${FLEX} ${ALIGN_CENTER}`}>{content}</p>
                                </div>
                            );
                        })}
                        <div className={`production-introduction__dot-button-list ${FLEX}`}>
                            {content.map((_, index) => {
                                function handleClick() {
                                    setCurrentContentIndex(index);
                                }
                                return (
                                    <DotButton
                                        isActive={currentContentIndex === index}
                                        key={index}
                                        onClick={handleClick}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <LazyLoad offset={100}>
                        <div className="product-introduction__content-video-container">
                            <video src={video} autoPlay loop muted />
                        </div>
                    </LazyLoad>
                </div>
            </div>
        </div>
    );

}

function DotButton({
    isActive,
    onClick,
    ...buttonProps
}) {
    return (
        <button
            className={`product-introduction__dot-button ${isActive ? "active" : ""}`}
            onClick={onClick}
            {...buttonProps}
        ></button>
    );
}

const content = [
    {
        video: imgUrl + "/website_images/newHome/003.webm",
        trait: "稳定高效的",
        name: "生产执行管控系统",
        abbr: "MES",
        details: [
            {
                title: "专注排程管理，保证生产稳定有序进行",
                content: "渐近线致力于解决生产排程管理的难题；",
            },
            {
                title: "不同维度，同样专注",
                content: "渐近线在复杂生产过程管理和单一设备管理上同样专注；",
            },
            {
                title: "完备质量管理，助力信息化转型",
                content: "渐近线针对质量管控的信息化，具备独到的见解；",
            },
        ],
    },
    {
        video: imgUrl + "/website_images/newHome/001.webm",
        trait: "灵巧完备的",
        name: "智能仓储管理系统",
        abbr: "WMS",
        details: [
            {
                title: "构建智能制造框架，实现流程高效监管",
                content: "渐近线的仓储核心功能管理系统具有极高的专业性；",
            },
            {
                title: "更快捷，更迅速的多设备交互管理",
                content: "渐近线对于多设备对接的交互管理问题，具有丰富的攻克经验；",
            },
            {
                title: "坚实底层基础，助力智慧仓储",
                content: "渐近线精通基础信息与高级数据的管理；",
            },
        ],
    },
    {
        video: imgUrl + "/website_images/newHome/002.webm",
        trait: "可视易用的",
        name: "智能仓储控制系统",
        abbr: "WCS",
        details: [
            {
                title: "高效率的信息展现是我们不变的追求",
                content: "渐近线专注于梳理及拆分系统核心功能，并将其高效展示；",
            },
            {
                title: "不仅稳定高效，还能兼容易用",
                content: "渐近线掌握高效的PLC通讯、畅通的指令执行与反馈的核心技术；",
            },
            {
                title: "特殊化定制，满足独特需求",
                content: "渐近线解决了许多复杂的需求，具备丰富的项目经验；",
            },
        ],
    },
    {
        video: imgUrl + "/website_images/newHome/004.webm",
        trait: "精准可靠的",
        name: "工业仿真孪生平台",
        abbr: "IS&DT",
        details: [
            {
                title: "实时数据反馈，源自真实物理引擎",
                content: "渐近线采集的数据均来自真实的实时数据反馈；",
            },
            {
                title: "自研运动引擎，确保数据高度仿真",
                content: "渐近线自研的运动仿真引擎能够对仿真流程进行真实的呈现；",
            },
            {
                title: "生成分析报告，辅助精确决策",
                content: "渐近线能够依据仿真结果，为您提供最优解决方案；",
            },
        ],
    },
];
