import InitializeLayouts from 'components/InitializeLayouts';
import SecurityLayout from 'components/SecurityLayout';
import HomePage from 'pages/HomePage';
import ProductServicesPage from 'pages/ProductServicesPage/ProductServicesPage';
import JobsPage from 'pages/JobsPage';
import AboutUsPage from 'pages/AboutUsPage';
import ContactUsPage from 'pages/ContactUsPage/ContactUsPage';

import { ROUTERS } from 'constant/path';

export const constantRouterMap = [
  {
    path: '/',
    component: InitializeLayouts,
    routes: [
      {
        path: '/',
        component:  SecurityLayout,
        routes: [
          {
            path: ROUTERS.HOME_PAGE.PATH,
            exact: true,
            component: HomePage,
            meta: { title: ROUTERS.HOME_PAGE.TITLE }
          },
          {
            path: ROUTERS.PRODUCT_SERVICES_PAGE.PATH,
            exact: true,
            component: ProductServicesPage,
            meta: { title: ROUTERS.PRODUCT_SERVICES_PAGE.TITLE }
          },
          {
            path: ROUTERS.ABOUT_US_PAGE.PATH,
            exact: true,
            component: AboutUsPage,
            meta: { title: ROUTERS.ABOUT_US_PAGE.TITLE }
          },
          {
            path: ROUTERS.JOBS_PAGE.PATH,
            exact: true,
            component: JobsPage,
            meta: { title: ROUTERS.JOBS_PAGE.TITLE }
          },
          {
            path: ROUTERS.CONTACT_US_PAGE.PATH,
            exact: true,
            component: ContactUsPage,
            meta: { title: ROUTERS.CONTACT_US_PAGE.TITLE }
          }
        ]
      }
    ]
  }
];
