import React, { Component } from "react";

class BaiDuMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnLine: navigator.onLine && window.BMap,
    };
  }
  initMap() {
    this.baiDuMap = new window.BMap.Map(this.mapRef);
    this.baiDuMap.enableScrollWheelZoom(true);
    this.addMapControl();
  }
  addMapControl = () => {
    const { BMap, BMAP_NORMAL_MAP, BMAP_HYBRID_MAP, BMAP_PERSPECTIVE_MAP } =
      window;
    const { baiDuMap } = this;
    baiDuMap.addControl(
      new BMap.MapTypeControl({
        mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP, BMAP_PERSPECTIVE_MAP],
      })
    );
    baiDuMap.addControl(new BMap.NavigationControl());
    baiDuMap.addControl(new BMap.ScaleControl());
    const oveCtrl = new BMap.OverviewMapControl();
    oveCtrl.changeView();
    baiDuMap.addControl(oveCtrl);
  };
  addressResolution = (address) => {
    const myGeo = new window.BMap.Geocoder();
    myGeo.getPoint(address, (point) => {
      if (point) {
        this.setMarkInfo(point);
      }
    });
  };
  setMarkInfo = (point) => {
    this.baiDuMap.centerAndZoom(point, 19);
    var myIcon = new window.BMap.Icon(
      require("assets/images/common/icon_contactus_locate_nor.png").default,
      new window.BMap.Size(32, 32)
    );
    this.baiDuMap.addOverlay(new window.BMap.Marker(point, { icon: myIcon }));
  };
  componentDidMount() {
    if (this.state.isOnLine) {
      this.initMap();
      this.addressResolution(this.props.defaultAddress);
    }
  }
  render() {
    return (
      <div ref={(r) => (this.mapRef = r)} className={this.props.mapClass} />
    );
  }
}
export default BaiDuMap;
