import React from "react";

import "components/Mobile/AboutUsComponents/TeamLeader/TeamLeader.css";
import { ABOUT_US_INFO } from "constant";

const TeamLeader = () => {

  const {
    ABOUT_US_COMPANY_TEAM_TITLE = "",
    ABOUT_US_COMPANY_TEAM_TITLE_DESC = "",
    ABOUT_US_COMPANY_TEAM_LIST = [],
  } = ABOUT_US_INFO;
  
  return (
    <div className="about-us__team-leader">
      <div className="about-us__team-leader-title">
        { ABOUT_US_COMPANY_TEAM_TITLE }
      </div>
      <div className="about-us__team-leader-desc">
        { ABOUT_US_COMPANY_TEAM_TITLE_DESC }
      </div>
      <div className="about-us__team-leader-list">
        { ABOUT_US_COMPANY_TEAM_LIST.map((item, index) => (
          <div className="about-us__team-item" key={index}>
            <img src={item.PHOTO} alt="" />
            <div className="leader-name">{ item.NAME }</div>
            <div className="leader-position">{ item.TITLE }</div>
            <div className="leader-position__desc">
              { item.DESC }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default TeamLeader
