import React from "react";

import {
  JOBS_INFO,
  COMPANY_HR_EMAIL
} from "constant";
import "components/Mobile/JobsComponents/JoinUs/JoinUs.css";

const JoinUs = () => {

  const {
    JOBS_JOIN_US_TITLE = "",
    JOBS_JOIN_US_DESC = "",
    JOBS_JOIN_US_CONTACT_SUB_TITLE = "",
    JOBS_JOIN_US_COPY_ICON_MOBILE = "",
    JOBS_JOIN_US_FOLLOW = "",
    JOBS_JOIN_US_LIST = [],
    MOBILE_JOIN_US_IMG = "",
  } = JOBS_INFO;
  
  const onCopy = () => {
    var copyDOM = document.getElementById("info");
    var range = document.createRange();
    window.getSelection().removeAllRanges(); // 清除已有的
    range.selectNode(copyDOM); // 选中要复制的
    window.getSelection().addRange(range); // 执行选中元素
    var successful = document.execCommand("copy"); // 执行 copy 操作
    if (successful) {
      alert("复制成功");
    } else {
      alert("复制失败");
    }
    // 移除选中的元素
    window.getSelection().removeAllRanges();
  }
  return (
    <div className="join-us">
      <div
        className="mb-container global__bg"
        style={{
          backgroundImage: `url(${MOBILE_JOIN_US_IMG})`,
        }}
      >
        <div className="join-us__title">{ JOBS_JOIN_US_TITLE }</div>
        <div className="join-us__desc">{ JOBS_JOIN_US_DESC }</div>
        <div className="join-us__contact">
          <span>
            { JOBS_JOIN_US_CONTACT_SUB_TITLE }
            <span id="info">{ COMPANY_HR_EMAIL }</span>
          </span>
          <i
            onClick={onCopy}
            style={{
              backgroundImage: `url(${JOBS_JOIN_US_COPY_ICON_MOBILE})`,
            }}
            className="global__bg"
          />
        </div>
        <div className="join-us__follow">{ JOBS_JOIN_US_FOLLOW }</div>
        <div className="join-us__list">
          { JOBS_JOIN_US_LIST.map((item, index) => (
            <div className="join-us__item" key={index}>
              <img src={item.URL} alt="" />
              <div>
                <div className="join-us-item__name">{ item.TITLE }</div>
                <div className="join-us-item__desc paragraph_limit ">
                  { item.DESC }
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default JoinUs
