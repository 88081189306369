import React, { Component } from 'react';

import PcJobsBanner from 'components/Pc/JobsComponents/JobsBanner/JobsBanner';
import PcJobList from 'components/Pc/JobsComponents/JobList/JobList';
import PcJoinUs from 'components/Pc/JobsComponents/JoinUs/JoinUs';
import MobileJobsBanner from 'components/Mobile/JobsComponents/JobsBanner/JobsBanner';
import MobileJobList from 'components/Mobile/JobsComponents/JobList/JobList';
import MobileJoinUs from 'components/Mobile/JobsComponents/JoinUs/JoinUs';

class JobsPage extends Component {
  viewJobs = () => {
    if (this.jobsRef) {
      const top = this.jobsRef.current?.getBoundingClientRect().top;
      const pageYOffset = window.pageYOffset;
      const offset = top + pageYOffset - 110;
      window.scroll({
        top: offset,
        behavior: 'smooth'
      });
    }
  };
  render() {
    const { mobileDevice } = this.props;
    return (
      <div className="jobs">
        {!mobileDevice ? (
          <>
            <PcJobsBanner viewJobs={this.viewJobs} />
            <PcJobList getJobsListRef={r => (this.jobsRef = r)} />
            <PcJoinUs />
          </>
        ) : (
          <>
            <MobileJobsBanner />
            <MobileJobList />
            <MobileJoinUs />
          </>
        )}
      </div>
    );
  }
}
export default JobsPage;
