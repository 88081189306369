export const ROUTERS = {
  HOME_PAGE: {
    PATH: '/',
    TITLE: '首页'
  },
  PRODUCT_SERVICES_PAGE: {
    PATH: '/products',
    TITLE: '产品服务'
  },
  ABOUT_US_PAGE: {
    PATH: '/about',
    TITLE: '关于我们'
  },
  JOBS_PAGE: {
    PATH: '/jobs',
    TITLE: '加入我们'
  },
  CONTACT_US_PAGE: {
    PATH: '/contact',
    TITLE: '联系我们'
  }
}
