import React from "react";

import { JOBS_INFO } from "constant";
import "components/Mobile/JobsComponents/JobsBanner/JobsBanner.css";

const JobsBanner = () => {

  const {
    MOBILE_JOBS_BANNER_IMG = "",
    JOBS_BANNER_TITLE = "",
    JOBS_BANNER_TITLE_DESC1 = "",
    MOBILE_JOBS_BANNER_WELFARE_LIST = [],
  } = JOBS_INFO;
  
  return (
    <div
      className="jobs-banner__wrapper global__bg"
      style={{
        backgroundImage: `url(${MOBILE_JOBS_BANNER_IMG})`,
      }}
    >
      <div className="jobs-banner__wrapper-main">
        <div className="jobs-banner__title">{ JOBS_BANNER_TITLE }</div>
        <div className="jobs-banner__desc">{ JOBS_BANNER_TITLE_DESC1 }</div>
        {/* <Link className="jobs-banner__btn" to={JOBS_BANNER_BUTTON_URL}>
          { JOBS_BANNER_BUTTON }
        </Link> */}
        <div className="jobs-banner__list">
          { MOBILE_JOBS_BANNER_WELFARE_LIST.map((item, index) => (
            <div className="jobs-banner__item" key={index}>
              <i
                className="global__bg"
                style={{
                  backgroundImage: `url(${item.ICON})`,
                }}
              />
              <span>{ item.TITLE }</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default JobsBanner
