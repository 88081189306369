import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PRODUCT_SERVICES_INFO } from "constant/index";
import "components/Mobile/ProductServicesComponent/ProductServicesBanner/ProductServicesBanner.css";

class ProductServicesBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
    }
  }

  timer = null;

  componentDidMount() {
    this.autoPlay();
  }

  autoPlay = () => {
    if (this.timer) {
      this.clearTimer();
    }
    this.timer = setTimeout(() => {
      let { currentIndex } = this.state
      const { PRODUCT_SERVICES_BANNER_LIST = [] } = PRODUCT_SERVICES_INFO
      const addNum = currentIndex + 1
      if (addNum >= PRODUCT_SERVICES_BANNER_LIST.length) {
        this.setState({
          currentIndex: 0,
        })
      } else {
        this.setState({
          currentIndex: addNum,
        })
      }
      this.autoPlay();
    }, 3000)
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer = () => {
    this.timer && clearTimeout(this.timer);
    this.timer = null
  }

  onTouchStart = (currentIndex) => {
    this.clearTimer();
    this.setState({
      currentIndex,
    })
  }

  onTouchEnd = () => {
    this.autoPlay();
  }
  
  render() {
    const {
      PRODUCT_SERVICES_TITLE = "",
      PRODUCT_SERVICES_TITLE_DESC = "",
      MOBILE_PRODUCT_SERVICES_BANNER_IMG = "",
      PRODUCT_SERVICES_BANNER_MORE_TITLE = "",
      PRODUCT_SERVICES_BANNER_MORE_URL = "",
      PRODUCT_SERVICES_BANNER_LIST = [],
    } = PRODUCT_SERVICES_INFO;

    const { currentIndex } = this.state;

    const {
      ICON_2X = "",
      CH_TITLE = "",
      EN_TITLE = "",
      TYPE = "",
      CONTENT = "",
    } = PRODUCT_SERVICES_BANNER_LIST[currentIndex];

    return (
      <div className="product-services__wrapper">
        <div className="mb-container">
          <h2 className="product-services__wrapper-title">
            { PRODUCT_SERVICES_TITLE }
          </h2>
          <p className="product-services__wrapper-desc">
            { PRODUCT_SERVICES_TITLE_DESC }
          </p>
          <div
            className="product-services__wrapper-main global__bg"
            style={{
              backgroundImage: `url(${MOBILE_PRODUCT_SERVICES_BANNER_IMG})`,
            }}
          >
            <div
              className="product-services__wrapper-center global__bg"
              style={{
                backgroundImage: `url(${ICON_2X})`,
              }}
            />
            { PRODUCT_SERVICES_BANNER_LIST.map((item, index) => (
              <div
                className="product-services__wrapper-slide"
                onTouchStart={() => this.onTouchStart(index)}
                onTouchEnd={this.onTouchEnd}
                key={index}
              >
                <div>
                  <img src={item.ICON_2X} alt="" />
                  <span>{item.CH_TITLE}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="product-services__wrapper-main__detail">
            <div className="product-services__wrapper-main__detail-title">
              { CH_TITLE }
            </div>
            <div className="product-services__wrapper-main__detail-info">
              <span>{ EN_TITLE }</span>
              <span>{ TYPE }</span>
              <span>{ CONTENT }</span>
            </div>
          </div>
          <Link
            className="product-services__wrapper-more-btn"
            to={PRODUCT_SERVICES_BANNER_MORE_URL}
          >
            { PRODUCT_SERVICES_BANNER_MORE_TITLE }
          </Link>
        </div>
      </div>
    )
  }
}
export default ProductServicesBanner
