import React, { useState, useRef, useEffect } from "react";

import { JOBS_INFO } from "constant";
import Pager from "components/Pager/Pager";
import "components/Pc/JobsComponents/JobList/JobList.css";

const JobList = (props) => {
  
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);
  const [list, setList] = useState([]);

  const jobsRef = useRef();

  useEffect(() => {
    props.getJobsListRef && props.getJobsListRef(jobsRef);
  }, [props])

  const {
    JOBS_LIST_INFO: {
      JOBS_TITLE = "",
      JOBS_TITLE_DESC = "",
      // JOBS_SEARCH_NAME = "",
      // JOBS_SEARCH_ICON = "",
      // JOBS_SEARCH_PLACEHOLDER = "",
      // JOBS_TYPE_PLACEHOLDER = "",
      JOBS_LIST = [],
      JOBS_TYPE_SELECT_ICON = "",
    },
  } = JOBS_INFO;

  const changeIndex = (currentIndex) => {
    setCurrentIndex(currentIndex);
    setIsClicked(!isClicked);
  }

  const onChange = (params) => {
    const { current, pageSize } = params;
    const newList = JOBS_LIST.slice((current -1) * pageSize, current * pageSize);
    setList(newList);
  }
  return (
    <div className="jobs-page full-page ">
      <div className="container">
        <div className="jobs-page__info" ref={jobsRef}>
          <div className="jobs-page__info-title">{ JOBS_TITLE }</div>
          <div className="jobs-page__info-desc">{ JOBS_TITLE_DESC }</div>
        </div>
        {/* <div className="jobs-page__search">
          <input type="text" placeholder={JOBS_SEARCH_PLACEHOLDER}></input>
          <select className="job-select">
            <option value="all">{JOBS_TYPE_PLACEHOLDER}</option>
            {JOBS_LIST.map((item, index) => (
              <option value={item} key={index}>
                {item.NAME}
              </option>
            ))}
          </select>
          <button>
            <i
              style={{
                backgroundImage: `url(${JOBS_SEARCH_ICON})`,
              }}
              className="global__bg"
            ></i>
            <span>{JOBS_SEARCH_NAME}</span>
          </button>
        </div> */}
        <div className="jobs-page__list">
          { list.map((item, index) => (
            <div
              className={`jobs-page__list-item d-flex justify-content-between align-items-center ${
                currentIndex === index ? "jobs-page__item-active" : ""
              }`}
              key={index}
            >
              <div className="jobs-page__list-item-name">{ item.NAME }</div>
              <div className="jobs-page__list-item-detail">
                <span>{ item.ADDRESS }</span>
                <span>{ item.DEPARTMENT }</span>
                <span>{ item.WAY }</span>
              </div>
              <div className="jobs-page__list-item-action">
                <div onClick={() => changeIndex(index)}>
                  <span>{ item.ACTION }</span>
                  <i
                    className="global__bg"
                    style={{
                      backgroundImage: `url(${JOBS_TYPE_SELECT_ICON})`,
                      transform:
                        currentIndex === index && isClicked
                          ? "rotateZ(180deg)"
                          : "rotateZ(0deg)",
                    }}
                  />
                  <ul className="job-page__list-item-action__select">
                    { item?.ACTION_LIST.map((job, v) => (
                      <li
                        style={{
                          height:
                            currentIndex === index && isClicked ? "36px" : 0,
                        }}
                        key={v}
                      >
                        <a
                          href={job.LINK}
                          target="_blank"
                          rel="noopener  noreferrer"
                        >
                          { job.TITLE }
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="jobs-page_pager">
          <Pager total={JOBS_LIST.length} pageSize={7} onChange={onChange} />
        </div>
      </div>
    </div>
  )
}
export default JobList
