import { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";

import { CSS_CLASS } from "../../../../constant";
import "./index.css";

const {
    FLEX,
    ALIGN_CENTER,
    ALIGN_START,
    JUSTIFY_BETWEEN,
    JUSTIFY_CENTER,
} = CSS_CLASS;

const INCREASING_TIME_DEFAULT = 1.25; // 从0升到目标值消耗的秒数

export default function () {

    const containerRef = useRef(null);

    const refs = useRef(null);
    function getMap() {
        if (!refs.current) {
            refs.current = new Map();
        }
        return refs.current;
    }

    useEffect(() => {
        // 滚动到视口内开始数字自增
        // 仅触发一次
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    for (const [_, ref] of refs.current) {
                        ref?.start();
                    }
                    observer.unobserve(containerRef.current);
                }
            });
        }, {
            threshold: 0.25,
        });
        observer.observe(containerRef.current);
        return () => {
            observer.disconnect();
            for (const [_, ref] of refs.current) {
                ref?.clear();
            }
        };
    }, []);

    return (
        <div className="result-overview__container" ref={containerRef}>
            <div className="result-overview__main-container">
                {rows.map((row, index) => {
                    return (
                        <div key={index} className={`result-overview__main-row ${FLEX} ${JUSTIFY_BETWEEN} ${ALIGN_START}`}>
                            {row.map(item => {
                                const {
                                    title,
                                    subTitle,
                                    content,
                                } = item;

                                return (
                                    <div className="result-overview__main-row-item" key={subTitle}>
                                        <RateIncreasingFromZero
                                            target={title}
                                            ref={(node) => {
                                                const nodeMap = getMap();
                                                if (node) {
                                                    nodeMap.set(subTitle, node);
                                                } else {
                                                    nodeMap.delete(subTitle);
                                                }
                                            }}
                                        />
                                        <h4>{subTitle}</h4>
                                        <p>{content}</p>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <div className="result-overview__decoration-1">
                <div className="result-overview__decoration-1-gradient"></div>
            </div>
            <div className="result-overview__decoration-2">
                <div className="result-overview__decoration-2-gradient"></div>
            </div>
            <div className="result-overview__decoration-3">
                <div className="result-overview__decoration-3-gradient"></div>
            </div>
            <div className={`result-overview__circle-outer ${FLEX} ${JUSTIFY_CENTER} ${ALIGN_CENTER}`}>
                <div className="result-overview__circle-inner"></div>
            </div>
            <div className="result-overview__mask"></div>
        </div>
    );
}

const RateIncreasingFromZero = forwardRef(function ({
    target = 100,
    seconds = INCREASING_TIME_DEFAULT,
}, ref) {
    const [current, setCurrent] = useState(0);

    useImperativeHandle(ref, () => {
        let timerId = 0,
            current = 0;
        const interval = seconds / target * 1000;
        function start() {
            function increase() {
                if (current < target) {
                    setCurrent(pre => Math.min(target, pre + 1));
                    current++;
                    timerId = setTimeout(increase, interval);
                } else {
                    timerId = 0;
                }
            }
            timerId = setTimeout(increase, interval);
        }
        return {
            start,
            clear() {
                clearTimeout(timerId);
            },
        };
    }, []);

    return (
        <h3>{current}%</h3>
    );
});

const rows = [
    [
        {
            title: 60,
            subTitle: "效率提升",
            content: "渐近线的实施方法论极大提升了项目整体交付效率，实现项目全流程周期加速推进",
        },
        {
            title: 50,
            subTitle: "稳定性提升",
            content: "渐近线的分布式软件架构系统对多平台设备的稳定性、可追溯性均处于行业顶尖水平",
        },
    ],
    [
        {
            title: 80,
            subTitle: "成本下降",
            content: "渐近线高效准确的原生管理系统有效减少了项目的硬件成本投入，实现成本大幅下降",
        },
        {
            title: 100,
            subTitle: "客户复购率",
            content: "渐近线创立至今，项目均完美交付且效率远高于行业速度，是客户的首选软件供应商",
        },
    ],
];