import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from "react-router-config";

import { constantRouterMap } from 'router/router.config'
class App extends Component {
  render(){
    return (
        <>
          {renderRoutes(constantRouterMap)}
        </>
    );
  }
}
export default withRouter(App);
