import React from "react";

import { PRODUCT_SERVICES_INFO } from "constant";
import "components/Mobile/ProductServicesComponent/ProductServiceSupports/ProductServiceSupports.css";

const ProductServiceSupports = () => {

  const {
    PARTNERSHIP_INFO: {
      PARTNERSHIP_TITLE = "",
      PARTNERSHIP_IMG = "",
      PARTNERSHIP_LIST = [],
    },
  } = PRODUCT_SERVICES_INFO;
  
  return (
    <div className="product-services__supports">
      <div className="mb-container">
        <div className="product-services__supports-title">
          { PARTNERSHIP_TITLE }
        </div>
        <div className="product-services__supports-pic">
          <img src={PARTNERSHIP_IMG} alt="" />
        </div>
        <div className="product-services__supports-list">
          { PARTNERSHIP_LIST.map((item, index) => (
            <div className="product-services__supports-item" key={index}>
              <div className="title">{ item.TITLE }</div>
              <div className="desc">{ item.DESC }</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default ProductServiceSupports
