import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  COMPANY_PUBLIC_INFO,
  HOME_INFO
} from "constant";
import "components/Mobile/Header/Header.css";

class Header extends Component {
  constructor(props) {
    super()
    this.state = {
      currentIndex: 0,
      isMobileClick: false,
    }
  }

  componentDidMount() {
    this.refreshNav();
  }

  componentDidUpdate(preProps) {
    if (preProps.pathname !== this.props.pathname) {
      this.refreshNav();
    }
  }

  // 页面刷新后，根据当前路由设置index
  refreshNav = () => {
    const { route, pathname } = this.props
    const currentIndex = route.findIndex((item) => item.path === pathname)
    if (currentIndex >= 0) {
      this.setState({
        currentIndex,
      })
    }
  }

  onChangeIndex = (currentIndex) => {
    this.setState({
      currentIndex,
    })
  }

  onChangeMobileIndex = (currentIndex) => {
    this.setState({
      currentIndex,
      isMobileClick: false,
    })
  }

  onChangeMobileClickStatus = () => {
    const { isMobileClick } = this.state
    this.setState({
      isMobileClick: !isMobileClick,
    })
  }
  onClose = () => {
    this.setState({
      isMobileClick: false,
    })
  }

  render() {
    const { HEADER_LOGO_IMG = "" } = COMPANY_PUBLIC_INFO;
    const {
      MOBILE_HEADER_CLOSE_MENU_ICON = "",
      MOBILE_HEADER_OPEN_MENU_ICON = "",
    } = HOME_INFO;
    const { route = [] } = this.props;
    const { currentIndex, isMobileClick } = this.state;
    return (
      <div className="mobile-header">
        <div className="mb-container">
          <div className="header-root__top d-flex justify-content-between align-items-center flex-wrap">
            <div className="header-root__left">
              <img src={HEADER_LOGO_IMG} alt="" />
            </div>
            <div
              className="header-root__right"
              onClick={this.onChangeMobileClickStatus}
            >
              <i
                className="global__bg"
                style={{
                  backgroundImage: `url(${
                    !isMobileClick
                      ? MOBILE_HEADER_OPEN_MENU_ICON
                      : MOBILE_HEADER_CLOSE_MENU_ICON
                  })`,
                }}
              />
            </div>
          </div>
          <div className="header-root__bottom">
            <ul style={!isMobileClick ? null : { height: "5.351rem" }}>
              { route.length &&
                route.map((item, index) => (
                  <li
                    key={item.path}
                    onClick={() => this.onChangeMobileIndex(index)}
                  >
                    <Link
                      to={item.path}
                      className={`${
                        currentIndex === index ? "active-nav" : ""
                      }`}
                    >
                      { item.meta?.title }
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        { isMobileClick ? (
          <div className="header-cover" onClick={this.onClose} />
        ) : null}
      </div>
    )
  }
}
export default Header
