import React, { Component } from "react";
import { Link } from "react-router-dom";

import { COMPANY_PUBLIC_INFO } from "constant";
import "components/Pc/Header/Header.css";

class Header extends Component {
  constructor(props) {
    super()
    this.state = {
      currentIndex: 0,
    }
  }

  componentDidMount() {
    this.refreshNav();
  }

  componentDidUpdate(preProps) {
    if (preProps.pathname !== this.props.pathname) {
      this.refreshNav();
    }
  }

  // 页面刷新后，根据当前路由设置index
  refreshNav = () => {
    const { route, pathname } = this.props
    const currentIndex = route.findIndex((item) => item.path === pathname)
    if (currentIndex >= 0) {
      this.setState({
        currentIndex,
      })
    }
  }

  onChangeIndex = (currentIndex) => {
    this.setState({
      currentIndex,
    })
  }

  render() {
    const { HEADER_LOGO_IMG = "" } = COMPANY_PUBLIC_INFO
    const { route = [] } = this.props
    const { currentIndex } = this.state
    return (
      <div className="header-root">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="header-root__left">
            <img src={HEADER_LOGO_IMG} alt="" />
          </div>
          <div className="header-root__right">
            { route.length &&
              route.map((item, index) => (
                <Link
                  className={`header-root__right-nav ${
                    currentIndex === index ? "active-nav" : ""
                  }`}
                  onClick={() => this.onChangeIndex(index)}
                  to={item.path}
                  key={item.path}
                >
                  { item.meta?.title }
                </Link>
              ))}
          </div>
        </div>
      </div>
    )
  }
}
export default Header
