import React from "react";

import "components/Mobile/AboutUsComponents/AboutUsBanner/AboutUsBanner.css";
import { ABOUT_US_INFO } from "constant";

const AboutUsBanner = () => {

  const {
    ABOUT_US_BANNRE_TITLE = "",
    ABOUT_US_BANNER_BACKGROUND_IMG = "",
    ABOUT_US_BANNER_DESC = "",
    MOBILE_ABOUT_US_BANNER_CELL_BACKGROUND_IMG = "",
    ABOUT_US_BANNER_IMG = "",
    ABOUT_US_BANNER_LIST = [],
  } = ABOUT_US_INFO;

  return (
    <div className="about-us__banner">
      <div
        className="about-us__banner-content global__bg"
        style={{
          backgroundImage: `url(${ABOUT_US_BANNER_BACKGROUND_IMG})`,
        }}
      >
        <span>{ ABOUT_US_BANNRE_TITLE }</span>
      </div>
      <div className="mb-container">
        <div
          className="about-us__company-info global__bg"
          style={{
            backgroundImage: `url(${MOBILE_ABOUT_US_BANNER_CELL_BACKGROUND_IMG})`,
          }}
        >
          <div className="about-us__company-info__desc">
            { ABOUT_US_BANNER_DESC }
          </div>
          <div className="about-us__company-info__pic">
            <img src={ABOUT_US_BANNER_IMG} alt="" />
          </div>
          <div className="about-us__company-info__list">
            { ABOUT_US_BANNER_LIST.map((otem, index) => (
              <div className="about-us__company-info__item" key={index}>
                <div>{ otem.RATIO }</div>
                <p>{ otem.TITLE }</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default AboutUsBanner
