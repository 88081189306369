import React, { Component } from "react";

import { HOME_INFO } from "constant";
import "components/Mobile/HomeComponents/BrandValue/BrandValue.css";

class BrandValue extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isClick: false,
    }
  }
  onChangeClickStatus = () => {
    const { isClick } = this.state
    this.setState({
      isClick: !isClick,
    })
  }
  render() {
    const {
      BRAND_VALUE_INFO: {
        BRAND_VALUE_TITLE = "",
        BARND_VALUE_DESC = "",
        BARND_VALUE_DETAIL = "",
        BRAND_VALUE_PICTURES = [],
      },
    } = HOME_INFO;
    const { isClick } = this.state;
    return (
      <div className="home-brand-value">
        <div className="mb-container">
          <div className="home-brand-value__title">{ BRAND_VALUE_TITLE }</div>
          <div
            className="home-brand-value__pics"
            onClick={this.onChangeClickStatus}
          >
            { BRAND_VALUE_PICTURES.map((item, index) => (
              <div
                className="home-brand-value__pics-item"
                key={index}
                style={{
                  top: `${item.MOBILE?.OFFSET}rem`,
                }}
              >
                <img
                  src={item.URL}
                  alt=""
                  style={{
                    transform: isClick ? "rotateY(180deg)" : "",
                    transitionDelay: `${index * 0.1}s`
                  }}
                />
                <img
                  src={item.ACTIVE_URL}
                  alt=""
                  style={{
                    transform: isClick ? "rotateY(0deg)" : "",
                    transitionDelay: `${index * 0.1}s`
                  }}
                />
              </div>
            ))}
          </div>
          <div className="home-brand-value__desc">{ BARND_VALUE_DESC }</div>
          <div className="home-brand-value__detail">{ BARND_VALUE_DETAIL }</div>
        </div>
      </div>
    )
  }
}
export default BrandValue
