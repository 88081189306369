import React from "react";

import { ABOUT_US_INFO } from "constant";
import PanelTitle from "components/Pc/PanelTitle/PanelTitle";
import "components/Pc/AboutUsComponents/CompanyTeam/CompanyTeam.css";

const CompanyTeam = () => {
  const {
    ABOUT_US_COMPANY_TEAM_TITLE = "",
    // ABOUT_US_COMPANY_TEAM_TITLE_DESC = "",
    ABOUT_US_COMPANY_TEAM_LIST = [],
  } = ABOUT_US_INFO;

  return (
    <div className="about-us__company-team">
      <div className="container">
        <PanelTitle
          title={ABOUT_US_COMPANY_TEAM_TITLE}
          style={{
            fontWeight: 500,
            textAlign: "center",
            marginBottom: "32px",
          }}
        />
        {/* <div className="about-us__company-team__title-desc">
            {ABOUT_US_COMPANY_TEAM_TITLE_DESC}
          </div> */}
        <div className="about-us__company-team__list d-flex justify-content-center">
          { ABOUT_US_COMPANY_TEAM_LIST.map((item, index) => (
            <div
              className="about-us__company-team__list-item d-flex flex-column align-items-center"
              key={index}
            >
              <div className="about-us__company-team__list-item__photo">
                <img src={item.PHOTO} alt="" />
              </div>
              <div className="about-us__company-team__list-item__name">
                { item.NAME }
              </div>
              <div className="about-us__company-team__list-item__title">
                { item.TITLE }
              </div>
              <div className="about-us__company-team__list-item__desc">
                { item.DESC }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default CompanyTeam
