import React from "react";
import { Link } from "react-router-dom";

import {
  COMPANY_PUBLIC_INFO,
  FOOTER_INFO
} from "constant";
import "components/Pc/Footer/Footer.css";

const Footer = () => {
  
  const CompanyLogo = () => {
    const { FOOTER_LOGO_IMG = "" } = COMPANY_PUBLIC_INFO
    return (
      <div className="footer-item">
        <img className="footer-company__logo" src={FOOTER_LOGO_IMG} alt="" />
      </div>
    )
  }

  const CompanyInfo = () => {
    const {
      COMPANY_INFO: { TITLE = "", LIST = [] },
    } = FOOTER_INFO

    return (
      <div className="footer-item">
        <dl>
          <dt>{ TITLE }</dt>
          { LIST.map((item, index) => (
            <dd key={index}>
              <Link to={item.LINK_TO}>{ item.LINK_TITLE }</Link>
            </dd>
          ))}
        </dl>
      </div>
    )
  }

  const CompanyProduct = () => {
    const {
      COMPANY_PRODUCT: { TITLE = "", LIST = [] },
    } = FOOTER_INFO
    return (
      <div className="footer-item">
        <dl>
          <dt>{TITLE}</dt>
          { LIST.map((item, index) => (
            <dd key={index}>
              <Link to={item.LINK_TO}>{ item.LINK_TITLE }</Link>
            </dd>
          ))}
        </dl>
      </div>
    )
  }

  const ContactUs = () => {
    const {
      CONTACT_US: { TITLE = "", LIST = [] },
    } = FOOTER_INFO
    return (
      <div className="footer-item">
        <dl>
          <dt className="footer-item__contact-title">{ TITLE }</dt>
          { LIST.map((item, index) => (
            <dd key={index} className="footer-item__contact-dd">
              <div className="footer-item__contact-info">
                <i
                  className="global__bg"
                  style={{
                    backgroundImage: `url(${item.ICON})`,
                  }}
                />
                <span>{`${item.TITLE}：${item.DESC}`}</span>
              </div>
            </dd>
          ))}
        </dl>
      </div>
    )
  }

  const WechatAttention = () => {
    const { FOOTER_QRCODE_IMG = "" } = COMPANY_PUBLIC_INFO
    const {
      WE_CHAT_INFO: { TITLE = "", SUB_TITLE = "" },
    } = FOOTER_INFO
    return (
      <div className="footer-item d-flex flex-column justify-content-center align-items-center">
        <div className="footer-item__wechat-title wechat-title">{ TITLE }</div>
        <div className="footer-item__wechat-sub__title wechat-title">
          {SUB_TITLE}
        </div>
        <div className="footer-item__wechat-qr">
          <img src={FOOTER_QRCODE_IMG} alt="" />
          {/* <div className="footer-item__contact-list d-flex justify-content-between">
            {LIST.map((item, index) => (
              <i
                key={index}
                className="global__bg"
                style={{
                  backgroundImage: `url(${item})`,
                }}
              />
            ))}
          </div> */}
        </div>
      </div>
    )
  }

  const CopyRight = () => {
    const {
      COPY_RIGHT_INFO: {
        COPYRIGHT_SIGN = "",
        COPYRIGHT_WEBSITE_RECORD_NUMBER = "",
        COPYRIGHT_WEBSITE_REGISTRATION_NAME = "",
        COPYRIGHT_WEBSITE_REGISTRATION_NUMBER = "",
      },
    } = FOOTER_INFO
    const { COMPANY_NAME = "" } = COMPANY_PUBLIC_INFO
    return (
      <div className="footer-bottom__copy d-flex justify-content-center">
        <span>{`${COPYRIGHT_SIGN}${COMPANY_NAME}`}</span>
        <span>{COPYRIGHT_WEBSITE_RECORD_NUMBER}</span>
        <span>{COPYRIGHT_WEBSITE_REGISTRATION_NAME}</span>
        <span>{COPYRIGHT_WEBSITE_REGISTRATION_NUMBER}</span>
      </div>
    )
  }

  return (
    <div className="footer-root">
      <div className="container">
        <div className="footer-top d-flex justify-content-around">
          <CompanyLogo />
          <CompanyInfo />
          <CompanyProduct />
          <ContactUs />
          <WechatAttention />
        </div>
        <div className="footer-bottom">
          <CopyRight />
        </div>
      </div>
    </div>
  )
}

export default Footer
