import { useRef, useState, useCallback, } from "react";

import useScrollIntoViewPortObserver from "./useScrollIntoViewPortObserver";

// 滚动至视口内时淡入, 将titleRef、titleStyle应用至目标DOM即可
export default function useTransitionTitle({
    transitionDuration = 0.5,
    transitionFunction = "linear",
} = {
    transitionDuration: 0.5,
    transitionFunction: "linear",
}) {
    const titleRef = useRef(null);
    const [titleStyle, setTitleStyle] = useState({
        opacity: 0,
        transition: `opacity ${transitionDuration}s ${transitionFunction}`,
    });
    const handleScrollIntoViewPort = useCallback(() => {
        setTitleStyle(pre => {
            return {
                ...pre,
                opacity: 1,
            };
        });
    }, []);
    useScrollIntoViewPortObserver({
        onScrollIntoViewPort: handleScrollIntoViewPort,
        targetNodeRef: titleRef,
    });

    return {
        titleRef,
        titleStyle,
    };
}