// import { CustomizedButton } from '../../../../components/CustomizedButton';
import { config } from 'utils/config';
import { CSS_CLASS } from "../../../../constant";
import "./index.css";
const { imgUrl } = config;

const {
    FLEX,
    ALIGN_CENTER,
} = CSS_CLASS;

export default function () {
    return (
        <div className={`section-one__container ${FLEX} ${ALIGN_CENTER}`}>
            <div className="section-one__mask"></div>
            <div className="section-one__video-container">
                <video
                    autoPlay
                    loop
                    muted
                    src={imgUrl + "/website_images/newHome/introduction.mp4"}
                />
            </div>
            <div className="section-one__text-container">
                <h4><span style={{ opacity: 0.75 }}>效率提升</span> <strong style={{color: "#1b7af8"}}>62%</strong></h4>
                <h3>看得见的智能制造</h3>
                <p>工业4.0时代工业转型领军人</p>
                <a href="#/contact" className="section-one__gradient-button">业务联络咨询</a>
                {/* <div>
                    <CustomizedButton style={{ marginRight: 16, }} type="primary">个人免费使用</CustomizedButton>
                    <CustomizedButton>企业版咨询</CustomizedButton>
                </div> */}
            </div>
        </div>
    );
}
