import { config } from 'utils/config';
const { imgUrl } = config;

export const COMPANY_HR_EMAIL = 'hr@asymptote.cloud';
const COMPANY_EMAIL_TITLE = '邮箱';
const COMPANY_EMAIL = 'service@asymptote.cloud';
const COMPANY_WEBSITE_TITLE = '网站';
const COMPANY_WEBSITE = 'www.asymptote.cloud';
const COMPANY_ADDRESS_TITLE = '地址';
const COMPANY_NAME = '深圳市渐近线科技有限公司';
export const COMPANY_ADDRESS =
  '深圳市南山区粤海街道高新区社区科苑南路3170号留学生创业大厦一期810';
// const COMPANY_TELEPHONE_TITLE = "电话";
// const COMPANY_TELEPHONE = "0755-26653124";

export const COMPANY_PUBLIC_INFO = {
  HEADER_LOGO_IMG: require('assets/images/common/header_logo_svg.svg').default,
  FOOTER_LOGO_IMG: require('assets/images/common/footer_logo_svg.svg').default,
  FOOTER_QRCODE_IMG: `${imgUrl}/website_images/common/footer_qrCode.png`,
  PAGER_LEFT_DEFAULT: require('assets/images/common/pager_left.png').default,
  PAGER_RIGHT_DEFAULT: require('assets/images/common/pager_right.png').default,
  COMPANY_NAME,
};
// 首页
export const HOME_INFO = {
  HOME_SWIPER_LIST: [
    {
      CH_TITLE: '智能制造软硬件一体化设计平台开创者',
      EN_TITLE: 'ComposeX - Industrial Design Forerunner',
      URL: '/products',
      BTN_TITLE: '了解更多',
      BACKGROUND_URL: `${imgUrl}/website_images/home/home-swiper-02.png`,
      MOBILE_BACKGROUND_URL: `${imgUrl}/website_images/home/home-swiper-mobile-02.png`,
    },
    {
      CH_TITLE: '孪生数字化工厂',
      EN_TITLE: 'Digital Twin Factory',
      URL: '/products',
      BTN_TITLE: '了解更多',
      BACKGROUND_URL: `${imgUrl}/website_images/home/home-swiper-01.png`,
      MOBILE_BACKGROUND_URL: `${imgUrl}/website_images/home/home-swiper-mobile-01.png`,
    },
    {
      CH_TITLE: '智能仓储 & 智能制造',
      EN_TITLE: 'ComposeX - Industrial Design Forerunner',
      URL: '/products',
      BTN_TITLE: '了解更多',
      BACKGROUND_URL: `${imgUrl}/website_images/home/home-swiper-03.png`,
      MOBILE_BACKGROUND_URL: `${imgUrl}/website_images/home/home-swiper-mobile-03.png`,
    },
  ],
  BRAND_VALUE_INFO: {
    BRAND_VALUE_TITLE: '合作品牌',
    BARND_VALUE_DESC:
      '深圳市渐近线科技有限公司（简称“渐近线”）是一家工业互联网领域的企业级工业场景软件设计方案服务商，致力于为具有工业背景的企业提供从软件设计到部署的全栈式解决方案，突出高效、准确、柔性的设计亮点，通过对用户knowhow的挖掘与提炼，全面深入地完成项目交付以及未来整体性能提升与柔性改善，帮助用户实现生产、营运、现代企业管理的整体优化和提升。',
    BARND_VALUE_DETAIL:
      '渐近线扎根于创业氛围浓厚的深圳，敢闯敢试敢于冒险的创业精神吸引了全球各地的菁英创客。团队自2019年创立，聚集了众多博士、硕士等学术大咖，他们始终倡导创新、多元和协同的团队氛围，并汲取Microsoft、Amazon、Linkedin、Oracle等硅谷互联网企业的成功经验，同国内智能制造企业团队相互匹配协调，完成了AT技术、IT技术，数据和智能制造为核心，聚焦工业一体化设计、设备互联、制造协同、运营管控、数据智能等应用场景，提供卓越的产品与解决方案。通过独特的技术手段，将生产过程中难以量化和可视化的内容，高效精准地呈现出来，帮助制造业企业掌控每一个生产瞬间，管理每一个生产细节。',
    BRAND_VALUE_PICTURES: [
      {
        URL: `${imgUrl}/website_images/home/brandValue/brand_value_01.png`,
        ACTIVE_URL: `${imgUrl}/website_images/home/brandValue/brand_value_active_01.png`,
        OFFSET: 96,
        MOBILE: {
          HEIGHT: 4.8,
          OFFSET: 0.96,
        },
      },
      {
        URL: `${imgUrl}/website_images/home/brandValue/brand_value_02.png`,
        ACTIVE_URL: `${imgUrl}/website_images/home/brandValue/brand_value_active_02.png`,
        OFFSET: 128,
        MOBILE: {
          HEIGHT: 4.8,
          OFFSET: 1.28,
        },
      },
      {
        URL: `${imgUrl}/website_images/home/brandValue/brand_value_04.png`,
        ACTIVE_URL: `${imgUrl}/website_images/home/brandValue/brand_value_active_03.png`,
        OFFSET: 64,
        MOBILE: {
          HEIGHT: 4.8,
          OFFSET: 0.64,
        },
      },
      {
        URL: `${imgUrl}/website_images/home/brandValue/brand_value_03.png`,
        ACTIVE_URL: `${imgUrl}/website_images/home/brandValue/brand_value_active_04.png`,
        OFFSET: 0,
        MOBILE: {
          HEIGHT: 4.8,
          OFFSET: 0,
        },
      },
      {
        URL: `${imgUrl}/website_images/home/brandValue/brand_value_05.png`,
        ACTIVE_URL: `${imgUrl}/website_images/home/brandValue/brand_value_active_05.png`,
        OFFSET: 64,
        MOBILE: {
          HEIGHT: 4.8,
          OFFSET: 0.64,
        },
      },
      {
        URL: `${imgUrl}/website_images/home/brandValue/brand_value_06.png`,
        ACTIVE_URL: `${imgUrl}/website_images/home/brandValue/brand_value_active_06.png`,
        OFFSET: 96,
        MOBILE: {
          HEIGHT: 4.8,
          OFFSET: 0.96,
        },
      },
    ],
  },
  COMPANY_FEATURE_INGO: {
    COMPANY_FEATURE_CONTENT: {
      TITLE: '企业特色',
      DESC: '渐近线科技以工业互联网平台为核心载体，聚焦工业一体化设计、设备互联、制造协同、运营管控、数据智能等应用场景，提供卓越的产品与解决方案',
      LIST: [
        {
          ICON: require('assets/images/home/companyFeature/feature_icon01.png')
            .default,
          CONTENT: '丰富的工业设计经验',
        },
        {
          ICON: require('assets/images/home/companyFeature/feature_icon02.png')
            .default,
          CONTENT: '卓越的大数据处理和分析能力',
        },
        {
          ICON: require('assets/images/home/companyFeature/feature_icon03.png')
            .default,
          CONTENT: '先进的跨平台应用开发能力',
        },
        {
          ICON: require('assets/images/home/companyFeature/feature_icon04.png')
            .default,
          CONTENT: '稀缺的全栈一体式开发实力',
        },
      ],
    },
    COMPANY_RECRUIT_CONTENT: {
      TITLE: '渐近线期待与你携手共进',
      DESC: '我们正在招募志同道合，才华横溢的伙伴，共同探索和研究智能制造类企业应用产品，期待你的加入，开启你和渐近线的故事',
      STATUS: '正在热招',
      RECRUIT_LIST: [
        {
          NAME: '高级后端软件工程师',
          URL: '/jobs',
          ICON: '',
        },
        {
          NAME: '营销总监',
          URL: '/jobs',
          ICON: '',
        },
        {
          NAME: '3D建模师',
          URL: '/jobs',
          ICON: '',
        },
        {
          NAME: '售前方案专家',
          URL: '/jobs',
          ICON: '',
        },
        {
          NAME: '更多',
          URL: '/jobs',
          ICON: require('assets/images/home/companyFeature/recruit_more_icon.png')
            .default,
        },
      ],
    },
  },
  MOBILE_HEADER_CLOSE_MENU_ICON:
    require('assets/images/common/mobile_header_close_menu_icon.png').default,
  MOBILE_HEADER_OPEN_MENU_ICON:
    require('assets/images/common/mobile_header_open_menu_icon.png').default,
};
// 产品服务
export const PRODUCT_SERVICES_INFO = {
  PRODUCT_SERVICES_TITLE: '产品服务',
  PRODUCT_SERVICES_TITLE_DESC:
    '通过独特的技术手段，将生产过程中难以量化和可视化的内容，高效精准地呈现出来，帮助制造业企业掌控每一个生产瞬间，管理每一个生产细节',
  PRODUCT_SERVICES_BANNER_IMG: `${imgUrl}/website_images/productServices/product_service_banner.png`,
  MOBILE_PRODUCT_SERVICES_BANNER_IMG: `${imgUrl}/website_images/productServices/mobile_product_service_banner.png`,
  PRODUCT_SERVICES_BANNER_MORE_TITLE: '了解更多',
  // 首页概括信息
  PRODUCT_SERVICES_GESTURE_ICON:
    require('assets/images/productServices/product_services_gesture_icon.png')
      .default,
  PRODUCT_SERVICES_EN_TITLE_ICON:
    require('assets/images/productServices/product_services_en_title_icon.png')
      .default,
  PRODUCT_SERVICES_MORE_ICON:
    require('assets/images/productServices/product_services_more.png').default,
  PRODUCT_SERVICES_LEFT_MORE:
    require('assets/images/productServices/product_services_left_more.png')
      .default,
  PRODUCT_SERVICES_LEFT_NORMAL:
    require('assets/images/productServices/product_services_left_normal.png')
      .default,
  PRODUCT_SERVICES_RIGHT_MORE:
    require('assets/images/productServices/product_services_right_more.png')
      .default,
  PRODUCT_SERVICES_RIGHT_NORMAL:
    require('assets/images/productServices/product_services_right_normal.png')
      .default,
  MOBILE_PRODUCT_SERVICES_LEFT_CLICK_ICON:
    require('assets/images/productServices/mobile_product_services_left_click_icon.png')
      .default,
  MOBILE_PRODUCT_SERVICES_LEFT_ICON:
    require('assets/images/productServices/mobile_product_services_left_icon.png')
      .default,
  MOBILE_PRODUCT_SERVICES_RIGHT_CLICK_ICON:
    require('assets/images/productServices/mobile_product_services_right_click_icon.png')
      .default,
  MOBILE_PRODUCT_SERVICES_RIGHT_ICON:
    require('assets/images/productServices/mobile_product_services_right_icon.png')
      .default,
  PRODUCT_SERVICES_BANNER_LIST: [
    {
      CH_TITLE: '云创',
      EN_TITLE: 'ComposeX',
      ICON_2X: `${imgUrl}/website_images/productServices/ComposeX_2x.png`,
      DESC: '智能制造软硬件一体化设计平台',
      TYPE: '仿真',
      CONTENT: '孪生',
      DETAIL:
        '下一代智能制造软硬件一体化设计平台，旨在通过虚拟现实技术完成设计。精准贴合从前期产品概念规范到产品投入使用再到后期维护的完整产品开发流程要求。提供3D设计、资料管理、模拟、技术文件汇编与电动设计等多种功能于一体，组件多样却操作便捷，可减少设计过程中的错误并提高产品质量。使用真实物理环境引擎，用于提高自然资源的可预测性、效率、安全性与永续性；协助设计生产交付运营是高效。',
      SOURCE:
        '截图自渐近线自主研发项目，以低代码/无代码的设计方式实现功能，准确评估和分析工厂的运行情况。极大地缩短了整个智能化工厂的前期规划和设计周期',
      LINK_TITLE: '了解更多',
      LINK_TO: '/products',
      URL: `${imgUrl}/website_images/productServices/ComposeX_01.png`,
      ACTIVE_URL: `${imgUrl}/website_images/productServices/ComposeX_01_active.png`,
      URL_2X: `${imgUrl}/website_images/productServices/ComposeX_2x_big.png`,
      MOBILE_ICON: require('assets/images/productServices/mobile_icon02.png')
        .default,
    },
    {
      CH_TITLE: '云镜',
      EN_TITLE: 'MirrorX',
      ICON_2X: `${imgUrl}/website_images/productServices/MirrorX_2x.png`,
      DESC: '工业仿真与孪生引擎',
      TYPE: '仿真',
      CONTENT: '孪生',
      DETAIL:
        '一款面向工厂环境搭建的真实物理引擎，通过高仿真度的物理数据映射至compose X，驱动数字孪生体融入真实物理特性，进行与真实物体几乎一直的仿真模拟，并将硬件所需的驱动程序赋予，快速且近乎真实的完成设计工厂整体执行效率运行情况的模拟。面向演示使用画面渲染引擎，可以达到令人震撼的画面效果。',
      SOURCE:
        '截图自煜林自动化电镀云镜项目，以上的数字化孪生工厂的外观长相和实际工厂一模一样，但却不是由现场摄像头拍摄，而是全部由工厂数据搭建呈现。能1:1复刻现场工艺及流程，实现全数字化呈现、控制及追溯',
      LINK_TITLE: '了解更多',
      LINK_TO: '/products',
      URL: `${imgUrl}/website_images/productServices/MirrorX_01.png`,
      ACTIVE_URL: `${imgUrl}/website_images/productServices/MirrorX_01_active.png`,
      URL_2X: `${imgUrl}/website_images/productServices/MirrorX_2x_big.png`,
      MOBILE_ICON: require('assets/images/productServices/mobile_icon03.png')
        .default,
    },
    {
      CH_TITLE: '云枢',
      EN_TITLE: 'CentreX',
      ICON_2X: `${imgUrl}/website_images/productServices/CentreX_2x.png`,
      DESC: '一站式MES管理系统',
      TYPE: '仿真',
      CONTENT: '孪生',
      DETAIL:
        '一款集成工厂现阶段需求、一站式整体交付的工业控制和管理引擎，基于微服务架构与分布式技术，准确清晰打通真实世界与Mirror X控制边界，同时满足现有标准可编程接口，支持导入自定义资源调度与任务模块，项目运营阶段完成智能运营所需的数据读取，检索和分析相关的专业资讯，让企业从工业大数据中解读出更多价值信息，并辅以即时监控与管理软件，处理所有资讯，帮助管理运营的同时更好、更快地决策。',
      SOURCE:
        '截图自渐近线自主研发云枢MES项目，生产计划、物料管理、品质管理、计划排程、过程控制、Andon管理、产品追溯、JIT生产、员工成本控制一站式解决',
      LINK_TITLE: '了解更多',
      LINK_TO: '/products',
      URL: `${imgUrl}/website_images/productServices/CentreX_01.png`,
      ACTIVE_URL: `${imgUrl}/website_images/productServices/CentreX_01_active.png`,
      URL_2X: `${imgUrl}/website_images/productServices/CentreX_2x_big.png`,
      MOBILE_ICON: require('assets/images/productServices/mobile_icon01.png')
        .default,
    },
    {
      CH_TITLE: '云合',
      EN_TITLE: 'Club X',
      ICON_2X: `${imgUrl}/website_images/productServices/Club_2X.png`,
      DESC: '智能工业开发者平台',
      TYPE: '仿真',
      CONTENT: '孪生',
      DETAIL:
        '基于云创平台提供的框架，充分利用低代码/无代码的方式帮助包括开发者，合作伙伴，客户在线的开发环境，提供了社交网络和社区功能，以便于非结构化环境汇总协作和社交内容的无缝共享和协作，并且提供市场解决方案和生成影像服务，将工程资料转化为专业人士可用且易读的资料。',
      SOURCE:
        '截图自渐近线生态开发者平台，以全新的语言和构成方式完成CentreX和MirrorX的全栈设计部署。入库、出库、退料、回料、仓储管理一步到位，全信息贯穿，无信息孤岛',
      LINK_TITLE: '了解更多',
      LINK_TO: '/products',
      URL: `${imgUrl}/website_images/productServices/ClubX_01.png`,
      ACTIVE_URL: `${imgUrl}/website_images/productServices/ClubX_01_active.png`,
      URL_2X: `${imgUrl}/website_images/productServices/ClubX_2x_big.png`,
      MOBILE_ICON: require('assets/images/productServices/mobile_icon01.png')
        .default,
    },
  ],
  PRODUCT_SERVICES_LIST: [
    {
      CH_TITLE: '云创',
      EN_TITLE: 'ComposeX',
      ICON_2X: `${imgUrl}/website_images/productServices/ComposeX_2x.png`,
      DESC: '智能制造软硬件一体化设计平台',
      TYPE: '仿真',
      CONTENT: '孪生',
      DETAIL:
        '下一代智能制造软硬件一体化设计平台，旨在通过虚拟现实技术完成设计。精准贴合从前期产品概念规范到产品投入使用再到后期维护的完整产品开发流程要求。提供3D设计、资料管理、模拟、技术文件汇编与电动设计等多种功能于一体，组件多样却操作便捷，可减少设计过程中的错误并提高产品质量。使用真实物理环境引擎，用于提高自然资源的可预测性、效率、安全性与永续性；协助设计生产交付运营是高效。',
      SOURCE:
        '截图自渐近线自主研发项目，以低代码/无代码的设计方式实现功能，准确评估和分析工厂的运行情况。极大地缩短了整个智能化工厂的前期规划和设计周期',
      LINK_TITLE: '了解更多',
      LINK_TO: '/products',
      URL: `${imgUrl}/website_images/productServices/ComposeX_01.png`,
      ACTIVE_URL: `${imgUrl}/website_images/productServices/ComposeX_01_active.png`,
      URL_2X: `${imgUrl}/website_images/productServices/ComposeX_2x_big.png`,
      MOBILE_ICON: require('assets/images/productServices/mobile_icon02.png')
        .default,
    },
    {
      CH_TITLE: '云镜',
      EN_TITLE: 'MirrorX',
      ICON_2X: `${imgUrl}/website_images/productServices/MirrorX_2x.png`,
      DESC: '工业仿真与孪生引擎',
      TYPE: '仿真',
      CONTENT: '孪生',
      DETAIL:
        '一款面向工厂环境搭建的真实物理引擎，通过高仿真度的物理数据映射至compose X，驱动数字孪生体融入真实物理特性，进行与真实物体几乎一直的仿真模拟，并将硬件所需的驱动程序赋予，快速且近乎真实的完成设计工厂整体执行效率运行情况的模拟。面向演示使用画面渲染引擎，可以达到令人震撼的画面效果。',
      SOURCE:
        '截图自煜林自动化电镀云镜项目，以上的数字化孪生工厂的外观长相和实际工厂一模一样，但却不是由现场摄像头拍摄，而是全部由工厂数据搭建呈现。能1:1复刻现场工艺及流程，实现全数字化呈现、控制及追溯',
      LINK_TITLE: '了解更多',
      LINK_TO: '/products',
      URL: `${imgUrl}/website_images/productServices/MirrorX_01.png`,
      ACTIVE_URL: `${imgUrl}/website_images/productServices/MirrorX_01_active.png`,
      URL_2X: `${imgUrl}/website_images/productServices/MirrorX_2x_big.png`,
      MOBILE_ICON: require('assets/images/productServices/mobile_icon03.png')
        .default,
    },
    {
      CH_TITLE: '云枢',
      EN_TITLE: 'CentreX',
      ICON_2X: `${imgUrl}/website_images/productServices/CentreX_2x.png`,
      DESC: '一站式MES管理系统',
      TYPE: '仿真',
      CONTENT: '孪生',
      DETAIL:
        '一款集成工厂现阶段需求、一站式整体交付的工业控制和管理引擎，基于微服务架构与分布式技术，准确清晰打通真实世界与Mirror X控制边界，同时满足现有标准可编程接口，支持导入自定义资源调度与任务模块，项目运营阶段完成智能运营所需的数据读取，检索和分析相关的专业资讯，让企业从工业大数据中解读出更多价值信息，并辅以即时监控与管理软件，处理所有资讯，帮助管理运营的同时更好、更快地决策。',
      SOURCE:
        '截图自渐近线自主研发云枢MES项目，生产计划、物料管理、品质管理、计划排程、过程控制、Andon管理、产品追溯、JIT生产、员工成本控制一站式解决',
      LINK_TITLE: '了解更多',
      LINK_TO: '/products',
      URL: `${imgUrl}/website_images/productServices/CentreX_01.png`,
      ACTIVE_URL: `${imgUrl}/website_images/productServices/CentreX_01_active.png`,
      URL_2X: `${imgUrl}/website_images/productServices/CentreX_2x_big.png`,
      MOBILE_ICON: require('assets/images/productServices/mobile_icon01.png')
        .default,
    },
    // {
    //   CH_TITLE: "云合",
    //   EN_TITLE: "Club X",
    //   ICON_2X: `${imgUrl}/website_images/productServices/Club_2X.png`,
    //   DESC: "智能工业开发者平台",
    //   TYPE: "仿真",
    //   CONTENT: "孪生",
    //   DETAIL:
    //     "基于云创平台提供的框架，充分利用低代码/无代码的方式帮助包括开发者，合作伙伴，客户在线的开发环境，提供了社交网络和社区功能，以便于非结构化环境汇总协作和社交内容的无缝共享和协作，并且提供市场解决方案和生成影像服务，将工程资料转化为专业人士可用且易读的资料。",
    //   SOURCE:
    //     "截图自渐近线生态开发者平台，以全新的语言和构成方式完成CentreX和MirrorX的全栈设计部署。入库、出库、退料、回料、仓储管理一步到位，全信息贯穿，无信息孤岛",
    //   LINK_TITLE: "了解更多",
    //   LINK_TO: "/products",
    //   URL: `${imgUrl}/website_images/productServices/ClubX_01.png`,
    //   ACTIVE_URL: `${imgUrl}/website_images/productServices/ClubX_01_active.png`,
    //   URL_2X: `${imgUrl}/website_images/productServices/ClubX_2x_big.png`,
    //   MOBILE_ICON: require("assets/images/productServices/mobile_icon01.png")
    //     .default,
    // },
  ],
  PARTNERSHIP_INFO: {
    PARTNERSHIP_TITLE: '伙伴权益',
    PARTNERSHIP_IMG: `${imgUrl}/website_images/productServices/partnership.png`,
    PARTNERSHIP_LIST: [
      {
        TITLE: '销售支持',
        DESC: '专属的渠道合作伙伴技术咨询、售前支持、方案支持、交付支持团队。为合作伙伴重要项目保驾护航',
      },
      {
        TITLE: '市场支持',
        DESC: '为合作伙伴带来商机、赢得商机，并为加盟的合作伙伴提供各类线上媒体推广，及线下的活动曝光机会',
      },
      {
        TITLE: '培训支持',
        DESC: '定期组织合作伙伴针对方案及技术开展交流活动，针对合作伙伴实际经营情况给予针对性的支持',
      },
      {
        TITLE: '官方授权',
        DESC: '授权合作伙伴认证，相关资质及产品销售的授权，全力支持合作伙伴市场开拓',
      },
    ],
  },
};
// 关于我们
export const ABOUT_US_INFO = {
  ABOUT_US_BANNRE_TITLE: '渐近线背后的故事',
  ABOUT_US_BANNER_BACKGROUND_IMG: `${imgUrl}/website_images/aboutUs/about_us_banner.png`,
  ABOUT_US_BANNER_DESC:
    '经过3年潜心研发，公司已初步完成下一代智能制造工业控制软件生成平台，该技术以数字孪生、三维仿真为载体，柔性化微服务架构技术和代码动态编译技术作为支撑，帮助企业及时精准完成智能工厂的构建、设计、仿真、洞察、驱动、部署、运营等多流程管理。我们以“链接全球、软硬一体、虚实映射、智能生产”为宗旨，愿与全球的合作伙伴携手，为制造业企业提供开放、灵活、易用、安全的工业互联网设计平台。',
  ABOUT_US_BANNER_DESC2:
    '提高工厂幸福度的同时兼顾绿色生态，推动经济发展，让人类生活更美好是渐近线始终不变的目标和使命。在该目标驱动下，渐近线追求卓越，不断突破。在公司成立首年完成了顺为资本数千万天使轮融资。2022年，我们将持续努力，吸纳人才，结交朋友，凝心聚力，同心同行。',
  ABOUT_US_BANNER_CELL_BACKGROUND_IMG: `${imgUrl}/website_images/aboutUs/about_us_background_cell.png`,
  MOBILE_ABOUT_US_BANNER_CELL_BACKGROUND_IMG: `${imgUrl}/website_images/aboutUs/mobile_about_us_background_cell.png`,
  ABOUT_US_BANNER_IMG: `${imgUrl}/website_images/aboutUs/about_us_cell.png`,
  ABOUT_US_BANNER_LIST: [
    {
      TITLE: '技术团队占比',
      RATIO: '80%+',
    },
    {
      TITLE: '科技研发投入',
      RATIO: '70%+',
    },
    {
      TITLE: '项目完成好评',
      RATIO: '100%',
    },
  ],
  ABOUT_US_COMPANY_TEAM_TITLE: '我们的团队',
  ABOUT_US_COMPANY_TEAM_TITLE_DESC: '公司文化的一段简约描述',
  ABOUT_US_COMPANY_TEAM_LIST: [
    {
      PHOTO: `${imgUrl}/website_images/aboutUs/gao.png`,
      NAME: '高山',
      TITLE: 'CEO',
      DESC: '美国南加州大学计算机工程硕士，擅长大数据处理与分析。先后在甲骨文、微软负责大数据基础服务和搜索引擎的设计与搭建。在微软，设计并带领团队实现了基于模型的迭代式查询重写框架，设计了覆盖微软领英所有搜索系统的，查询智能理解和重写架构。其所主导的项目平均每年为微软节省了近千万美元的运营支出。',
    },
    {
      PHOTO: `${imgUrl}/website_images/aboutUs/huang.png`,
      NAME: '黄腾昊',
      TITLE: '总经理',
      DESC: '美国布朗大学（常春藤）计算机工程硕士，擅长云服务与云应用开发。在甲骨文，负责其云平台旗舰产品Oracle Fusion Application的全生命周期服务开发，实现了甲骨文云端全生命周期管理的高可用及自动弹性扩展。后加入亚马逊特别项目组，负责亚马逊重要保密项目及前沿探索性产品的设计与研发。',
    },
    {
      PHOTO: `${imgUrl}/website_images/aboutUs/fang.png`,
      NAME: '方余华',
      TITLE: '市场总监',
      DESC: '曾任职于今天国际，华制智能，德富莱等多家智能制造相关企业。十多年的行业经验，熟悉中国工业行业特点，对市场变化与销售策略调整有丰富的实战经验。累计落地项目金额近10亿元人民币。对于项目部署实施及落地，软件匹配，项目实施，能够全流程管理。',
    },
  ],
  // 照片墙
  ABOUT_US_PHOTO_WALL_TITLE: '项目背后的故事',
  ABOUT_US_PHOTO_WALL_BACKGROUND_IMGTO: `${imgUrl}/website_images/aboutUs/photo_wall_banner.png`,
  MOBILE_ABOUT_US_PHOTO_WALL_BACKGROUND_IMG: `${imgUrl}/website_images/aboutUs/mobile_photo_wall_banner.png`,
  ABOUT_US_PHOTO_WALL_IMG01: `${imgUrl}/website_images/aboutUs/photo_wall_01.png`,
  MOBILE_ABOUT_US_PHOTO_WALL_IMG01: `${imgUrl}/website_images/aboutUs/mobile_photo_wall_01.png`,
  ABOUT_US_PHOTO_WALL_IMG02: `${imgUrl}/website_images/aboutUs/photo_wall_02.png`,
  MOBILE_ABOUT_US_PHOTO_WALL_IMG02: `${imgUrl}/website_images/aboutUs/mobile_photo_wall_02.png`,
  ABOUT_US_PHOTO_WALL_IMG03: `${imgUrl}/website_images/aboutUs/photo_wall_03.png`,
  MOBILE_ABOUT_US_PHOTO_WALL_IMG03: `${imgUrl}/website_images/aboutUs/mobile_photo_wall_03.png`,
  ABOUT_US_PHOTO_WALL_IMG04: `${imgUrl}/website_images/aboutUs/photo_wall_04.png`,
  MOBILE_ABOUT_US_PHOTO_WALL_IMG04: `${imgUrl}/website_images/aboutUs/mobile_photo_wall_04.png`,
  // 新闻动态
  ABOUT_US_NEWS_INFO_TITLE: '新闻动态',
  ABOUT_US_NEWS_INFO_DESC: '了解渐近线最新资讯',
  ABOUT_US_NEWS_INFO_LIST: [
    {
      TITLE: '渐近线科技pre A融资完成',
      URL: `${imgUrl}/website_images/aboutUs/news_01.jpg`,
      CONTENT: '深创投在渐近线完成天使轮后，快速决定携手共同征服山海...',
      TIME: '2022-03月-01',
    },
    {
      TITLE: '渐近线科技正式完成天使轮融资',
      URL: `${imgUrl}/website_images/aboutUs/news_02.jpg`,
      CONTENT:
        '经过12月一整个月的满满的会议行程，渐近线获得10多家机构青睐，最后携手顺为资本。',
      TIME: '2022-02月-08',
    },
    {
      TITLE: '渐近线科技的第一批测试项目完成交付',
      URL: `${imgUrl}/website_images/aboutUs/news_03.jpg`,
      CONTENT:
        '使用新方式同期交付3个项目，在质量一致的前提下做到了效率更优与传统方式并行。',
      TIME: '2022-01月-20',
    },
  ],
  MORE_TITLE: '了解更多',
  MORE_TITLE_URL: '/about',
};
export const JOBS_INFO = {
  JOBS_BANNER_IMG: `${imgUrl}/website_images/jobs/jobs_banner.png`,
  MOBILE_JOBS_BANNER_IMG: `${imgUrl}/website_images/jobs/mobile_jobs_banner.png`,
  JOBS_BANNER_TITLE: '加入渐近线团队',
  JOBS_BANNER_TITLE_DESC1:
    '加入一支才华横溢的创业团队，永远有梦想，永远有热情，永远相信科技的力量。',
  JOBS_BANNER_TITLE_DESC2: '加入渐近线，和我们一起让科技世界变得更美好',
  JOBS_BANNER_BUTTON: '查看全部职位',
  JOBS_BANNER_BUTTON_URL: '/jobs',
  JOBS_BANNER_WELFARE_LIST: [
    {
      TITLE: '满额五险一金，过节福利',
      ICON: require('assets/images/jobs/welfare_icon_01.png').default,
    },
    {
      TITLE: '岗位晋升明确，不走弯路',
      ICON: require('assets/images/jobs/welfare_icon_04.png').default,
    },
    {
      TITLE: '弹性工作制度，同事友好',
      ICON: require('assets/images/jobs/welfare_icon_02.png').default,
    },
    {
      TITLE: '年假制度完善，带薪旅游',
      ICON: require('assets/images/jobs/welfare_icon_05.png').default,
    },
    {
      TITLE: '股票期权激励，扁平管理',
      ICON: require('assets/images/jobs/welfare_icon_03.png').default,
    },
    {
      TITLE: '技能提升快速，大神带飞',
      ICON: require('assets/images/jobs/welfare_icon_06.png').default,
    },
  ],
  MOBILE_JOBS_BANNER_WELFARE_LIST: [
    {
      TITLE: '满额五险一金，过节福利',
      ICON: require('assets/images/jobs/welfare_icon_01.png').default,
    },
    {
      TITLE: '弹性工作制度，同事友好',
      ICON: require('assets/images/jobs/welfare_icon_02.png').default,
    },
    {
      TITLE: '股票期权激励，扁平管理',
      ICON: require('assets/images/jobs/welfare_icon_03.png').default,
    },
    {
      TITLE: '岗位晋升明确，不走弯路',
      ICON: require('assets/images/jobs/welfare_icon_04.png').default,
    },
    {
      TITLE: '年假制度完善，带薪旅游',
      ICON: require('assets/images/jobs/welfare_icon_05.png').default,
    },
    {
      TITLE: '技能提升快速，大神带飞',
      ICON: require('assets/images/jobs/welfare_icon_06.png').default,
    },
  ],
  JOBS_LIST_INFO: {
    JOBS_TITLE: '虚位以待',
    JOBS_TITLE_DESC: '欢迎各位有志于智能制造行业发展的人才加入！',
    JOBS_SEARCH_NAME: '搜索',
    JOBS_SEARCH_ICON: require('assets/images/jobs/input_icon_search.png')
      .default,
    MOBILE_JOBS_MORE_TITLE: '了解更多',
    MOBILE_JOBS_MORE_TITLE_URL: '/jobs',
    JOBS_SEARCH_PLACEHOLDER: '请输入关键字...',
    JOBS_TYPE_PLACEHOLDER: '所有职位',
    JOBS_TYPE_SELECT_ICON:
      require('assets/images/jobs/job_type_select_icon.png').default,
    JOBS_LIST: [
        {
            NAME: '销售助理',
            ADDRESS: '深圳',
            DEPARTMENT: '销售',
            WAY: '社招',
            ACTION: '投递',
            ACTION_LIST: [
              {
                TITLE: 'Boss直聘',
                LINK: 'https://www.zhipin.com/gongsi/job/a985c1e1000767da1nJ43t24GVs~.html',
              },
            ],
      },
      {
        NAME: '高级后端软件工程师',
        ADDRESS: '深圳',
        DEPARTMENT: '研发',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://m.zhipin.com/mpa/html/weijd/weijd-job/7227f9685bc82b901XV92tq8EFpY?aid=',
          },
          {
            TITLE: '智联招聘',
            LINK: 'https://jobs.zhaopin.com/CCL1323451980J40251872711.htm',
          },
        ],
      },
      {
        NAME: '大客户销售经理',
        ADDRESS: '深圳',
        DEPARTMENT: '销售',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/job_detail/afe3398fff932c691XV92tq_GVBX.html',
          },
        ],
      },
      {
        NAME: '售前技术支持（MES、WMS）',
        ADDRESS: '深圳',
        DEPARTMENT: '销售',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/gongsir/a985c1e1000767da1nJ43t24GVs~.html?ka=position-1',
          },
        ],
      },
      {
        NAME: '售前方案专家',
        ADDRESS: '深圳',
        DEPARTMENT: '销售',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/job_detail/c9df95d6e64977bf1XRz3d60EFZS.html',
          },
        ],
      },
      {
        NAME: 'Unity高级开发工程师',
        ADDRESS: '深圳',
        DEPARTMENT: '研发',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/job_detail/ac4ed3fc386515f21XZ42t-8GVVZ.html',
          },
        ],
      },
      {
        NAME: '高级3D应用软件工程师',
        ADDRESS: '深圳',
        DEPARTMENT: '研发',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/job_detail/7c991e177ba996961XV92tq8FFJZ.html',
          },
        ],
      },
      {
        NAME: '项目经理',
        ADDRESS: '深圳',
        DEPARTMENT: '管理',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/job_detail/96ebe6c465bf624d1Xd-2tW1EFdW.html',
          },
        ],
      },
      {
        NAME: '销售合伙人',
        ADDRESS: '深圳',
        DEPARTMENT: '销售',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/job_detail/713e6658d7d0ae141XB83d66EFRQ.html?lid=3z23cWRju90.search.1&securityId=c8etyTXKnegJV-G17wYoRq0e8oWY4ytQRtkhmz0FOeLiKv6qSPrhpUFnVNJxwfRADyPbzqS_eYJWaluXGRd5m3uG7sPc5Xr1icXvUfNGdbHfAwpAjXSzHIb3OGj1ay0w5ACDofyfjv7rLX6ZJ1fplHtXl5WutI287_QKGYa84xiq15Wt',
          },
        ],
      },
      {
        NAME: '售前支持',
        ADDRESS: '深圳',
        DEPARTMENT: '销售',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/job_detail/8970eb53168d78c51XV92tq8EVVZ.html',
          },
        ],
      },
      {
        NAME: '后端软件架构师',
        ADDRESS: '深圳',
        DEPARTMENT: '研发',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://m.zhipin.com/mpa/html/weijd/weijd-job/5e14d2d8b216509b1XZ509S6EVtU?aid=',
          },
        ],
      },
      {
        NAME: '3D应用架构师',
        ADDRESS: '深圳',
        DEPARTMENT: '研发',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://m.zhipin.com/mpa/html/weijd/weijd-job/f7099fe6c1a381261XV92tq8E1FY?aid=',
          },
        ],
      },
      {
        NAME: 'React前端工程师',
        ADDRESS: '深圳',
        DEPARTMENT: '研发',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/job_detail/d14e343d01f0a2031XB60tq6GVNV.html',
          },
        ],
      },
      {
        NAME: 'Unity初级开发工程师',
        ADDRESS: '深圳',
        DEPARTMENT: '研发',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/gongsir/a985c1e1000767da1nJ43t24GVs~.html?ka=company-jobs',
          },
        ],
      },
      {
        NAME: '高级3D应用软件工程师',
        ADDRESS: '深圳',
        DEPARTMENT: '研发',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://m.zhipin.com/mpa/html/weijd/weijd-job/7c991e177ba996961XV92tq8FFJZ?aid=',
          },
        ],
      },
      {
        NAME: '交付工程师',
        ADDRESS: '深圳',
        DEPARTMENT: '实施',
        WAY: '社招',
        ACTION: '投递',
        ACTION_LIST: [
          {
            TITLE: 'Boss直聘',
            LINK: 'https://www.zhipin.com/job_detail/b7ee3dd8adc418dd1Xdz2tS_E1FW.html',
          },
        ],
      },
    ],
  },
  JOBS_JOIN_US_TITLE: '加入我们',
  JOBS_JOIN_US_DESC: '发送邮件，投递简历，开启你和渐近线的故事',
  JOBS_JOIN_US_CONTACT_SUB_TITLE: '联系我们：',
  JOBS_JOIN_US_COPY_ICON: require('assets/images/jobs/copy_icon.png').default,
  JOBS_JOIN_US_COPY_ICON_MOBILE:
    require('assets/images/jobs/mobile_copy_icon.png').default,
  MOBILE_JOIN_US_IMG: `${imgUrl}/website_images/jobs/mobile_join_us.png`,
  JOBS_JOIN_US_FOLLOW: '实时动态与招聘信息，关注我们',
  JOBS_JOIN_US_LIST: [
    {
      TITLE: '渐近线科技微信公众号',
      URL: `${imgUrl}/website_images/jobs/qrcode_for_gh.jpg`,
      DESC: '渐近线科技图文信息和招聘信息发布平台，了解行业最新动态。',
    },
    {
      TITLE: '渐近线科技移动端官网',
      URL: `${imgUrl}/website_images/jobs/mobile_website.png`,
      DESC: '渐近线科技移动端官网平台，推广行业创新产品与解决方案。',
    },
    {
      TITLE: '渐近线科技微信小程序',
      URL: `${imgUrl}/website_images/jobs/mini_programer.png`,
      DESC: '掌上渐近线平台，关注渐近线最新动态，探索行业技术高地。',
    },
  ],
};
export const CONTACT_US_INFO = {
  CONTACT_US_TITLE: '联系我们',
  CONTACT_US_WAYS_TITLE: '联系信息',
  CONTACT_US_WAYS_DESC:
    '如有其他问题，包括合作机会，请发送电子邮件至 service@asymptote.cloud 或通过渐近线官方微信与我们联系，我们的团队将会尽快回复您。',
  CONTACT_US_LIST: [
    {
      TITLE: COMPANY_EMAIL_TITLE,
      DESC: COMPANY_EMAIL,
      ICON: require('assets/images/common/back_email.png').default,
    },
    // {
    //   TITLE: COMPANY_TELEPHONE_TITLE,
    //   DESC: COMPANY_TELEPHONE,
    // },
    {
      TITLE: COMPANY_WEBSITE_TITLE,
      DESC: COMPANY_WEBSITE,
      ICON: require('assets/images/common/website.png').default,
    },
    {
      TITLE: COMPANY_ADDRESS_TITLE,
      DESC: COMPANY_ADDRESS,
      ICON: require('assets/images/common/back_address.png').default,
    },
  ],
  FORM: {
    NAME_PLACEHOLDER: '姓名(必填)',
    TEL_PLACEHOLDER: '电话(必填)',
    EMAIL_PLACEHOLDER: '邮箱(必填)',
    COMPANY_PLACEHOLDER: '公司(必填)',
    QUESTION_PLACEHOLDER: '咨询问题',
    BUTTON_TITLE: '提交信息',
  },
};
export const FOOTER_INFO = {
  COMPANY_INFO: {
    TITLE: '公司概况',
    LIST: [
      {
        LINK_TITLE: '产品服务',
        LINK_TO: '/products',
      },
      {
        LINK_TITLE: '关于我们',
        LINK_TO: '/about',
      },
      {
        LINK_TITLE: '加入我们',
        LINK_TO: '/jobs',
      },
    ],
  },
  COMPANY_PRODUCT: {
    TITLE: '产品与服务',
    LIST: [
      {
        LINK_TITLE: 'ComposeX',
        LINK_TO: '/products',
      },
      {
        LINK_TITLE: 'MirrorX',
        LINK_TO: '/products',
      },
      {
        LINK_TITLE: 'CentreX',
        LINK_TO: '/products',
      },
      // {
      //   LINK_TITLE: "Club X",
      //   LINK_TO: "/products",
      // },
    ],
  },
  CONTACT_US: {
    TITLE: '联系我们',
    SUB_TITLE: '欢迎联系我们咨询交流',
    LIST: [
      {
        TITLE: COMPANY_EMAIL_TITLE,
        DESC: COMPANY_EMAIL,
        ICON: require('assets/images/footer/footer_email.png').default,
      },
      {
        TITLE: COMPANY_ADDRESS_TITLE,
        DESC: COMPANY_ADDRESS,
        ICON: require('assets/images/footer/footer_address.png').default,
      },
      // {
      //   TITLE: COMPANY_TELEPHONE_TITLE,
      //   DESC: COMPANY_TELEPHONE,
      //   ICON: require("assets/images/footer/footer_phone.png").default,
      // },
    ],
  },
  WE_CHAT_INFO: {
    TITLE: '微信扫码关注“渐近线”',
    SUB_TITLE: '获取最新动态',
    LIST: [
      require('assets/images/footer/footer_wechat_icon.png').default,
      require('assets/images/footer/footer_qq_icon.png').default,
      require('assets/images/footer/footer_micro-blog_icon.png').default,
    ],
  },
  COPY_RIGHT_INFO: {
    COPYRIGHT_SIGN: 'Copyright©',
    COPYRIGHT_WEBSITE_RECORD_NUMBER: '粤ICP备2021054396号-1',
    COPYRIGHT_WEBSITE_REGISTRATION_NAME: '粤公网安备',
    COPYRIGHT_WEBSITE_REGISTRATION_NUMBER: '11010702001922号',
  },
};

export const CSS_CLASS = {
  FLEX: "flex-container",
  INLINE_FLEX: "inline-flex-container",
  JUSTIFY_CENTER: "justify-center",
  JUSTIFY_BETWEEN: "justify-space-between",
  ALIGN_CENTER: "align-center",
  ALIGN_START: "align-start",
  ALIGN_END: "align-end",
};