import React from 'react';

import 'components/Pc/PanelTitle/PanelTitle.css';

const PanelTitle = props => {
  const { style = {}, title = '' } = props;
  return (
    <h2 className="home-panel__title" style={style}>
      { title }
    </h2>
  );
};
export default PanelTitle;
