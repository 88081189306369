import React, { Component } from "react"

// import PcHomeBanner from "components/Pc/HomeComponents/HomeBanner/HomeBanner"
// import PcBrandValue from "components/Pc/HomeComponents/BrandValue/BrandValue"
// import PcProductServices from "components/Pc/HomeComponents/ProductServices/ProductServices"
// import PcCompanyFeature from "components/Pc/HomeComponents/CompanyFeature/CompanyFeature"
import MobileHomeBanner from "components/Mobile/HomeComponents/HomeBanner/HomeBanner"
import MobileBrandValue from "components/Mobile/HomeComponents/BrandValue/BrandValue"
import MobileProductServices from "components/Mobile/HomeComponents/ProductServices/ProductServices"
import MobileCompanyFeature from "components/Mobile/HomeComponents/CompanyFeature/CompanyFeature"

import Home from "./Home";

class HomePage extends Component {
  render() {
    const { mobileDevice } = this.props
    return (
      <>
        {!mobileDevice ? (
          <>
            {/* <PcHomeBanner />
            <PcBrandValue />
            <PcProductServices />
            <PcCompanyFeature /> */}
            <Home />
          </>
        ) : (
          <>
          <div className="home-page">
            <MobileHomeBanner />
            <MobileBrandValue />
            <MobileProductServices />
            <MobileCompanyFeature />
          </div>
          </>
        )}
      </>
    )
  }
}
export default HomePage;
