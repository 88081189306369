import React from "react";

import { JOBS_INFO } from "constant";
import "components/Pc/JobsComponents/JobsBanner/JobsBanner.css";

const JobsBanner = (props) => {
  const onViewJobs = ()=> {
    props.viewJobs && props.viewJobs()
  }
  const {
    JOBS_BANNER_IMG = "",
    JOBS_BANNER_TITLE = "",
    JOBS_BANNER_TITLE_DESC1 = "",
    JOBS_BANNER_TITLE_DESC2 = "",
    JOBS_BANNER_BUTTON = "",
    JOBS_BANNER_BUTTON_URL = "",
    JOBS_BANNER_WELFARE_LIST = [],
  } = JOBS_INFO;
  return (
    <div
      className="jobs-page__banner full-page global__bg"
      style={{
        backgroundImage: `url(${JOBS_BANNER_IMG})`,
      }}
    >
      <div className="jobs-page__banner-main">
        <div className="d-flex flex-column justify-content-between full-box">
          <div className="jobs-page__banner-main__top">
            <div className="jobs-page__banner-title">{ JOBS_BANNER_TITLE }</div>
            <div className="jobs-page__banner-desc">
              <p>{ JOBS_BANNER_TITLE_DESC1 }</p>
              <p>{ JOBS_BANNER_TITLE_DESC2 }</p>
            </div>
            <button className="jobs-page__banner-btn" onClick={onViewJobs} to={JOBS_BANNER_BUTTON_URL}>
              { JOBS_BANNER_BUTTON }
            </button>
          </div>
          <div className="jobs-page__banner-main__bottom">
            <div className="jobs-page__banner-welfare__list d-flex flex-wrap">
              { JOBS_BANNER_WELFARE_LIST.map((item, index) => (
                <div className="jobs-page__banner-welfare__item" key={index}>
                  <i
                    className="global__bg"
                    style={{
                      backgroundImage: `url(${item.ICON})`,
                    }}
                  />
                  <span>{ item.TITLE }</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobsBanner;
