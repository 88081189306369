import React, { Component } from "react"

import { CONTACT_US_INFO, COMPANY_ADDRESS, COMPANY_PUBLIC_INFO } from "constant"
import BaiDuMap from "components/BaiDuMap"
import "pages/ContactUsPage/ContactUsPage.css"

class ContactUsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formValue: {},
    }
  }
  onChangeValue = (e) => {
    const { formValue } = this.state
    this.setState({
      formValue: {
        ...formValue,
        [e.target.name]: e.target.value,
      },
    })
  }
  onSubmitFormInfo = (event) => {
    event.preventDefault()
  }
  render() {
    const {
      CONTACT_US_TITLE = "",
      CONTACT_US_LIST = [],
      CONTACT_US_WAYS_DESC = "",
      // FORM: {
      //   NAME_PLACEHOLDER = "",
      //   TEL_PLACEHOLDER = "",
      //   EMAIL_PLACEHOLDER = "",
      //   COMPANY_PLACEHOLDER = "",
      //   QUESTION_PLACEHOLDER = "",
      //   BUTTON_TITLE = "",
      // },
    } = CONTACT_US_INFO
    const { FOOTER_QRCODE_IMG } = COMPANY_PUBLIC_INFO
    const { mobileDevice } = this.props

    return (
      <>
        {!mobileDevice ? (
          <div className="contact-us__page">
            <div className="container">
              <div className="contact-us__page-title">{CONTACT_US_TITLE}</div>
              {/* <PanelTitle
                title={CONTACT_US_TITLE}
                style={{
                  fontWeight: 500,
                  marginBottom: "32px",
                  textAlign: "center",
                }}
              /> */}
              {/* <div className="contact-us__list d-flex justify-content-between">
              {CONTACT_US_LIST.map((item, index) => (
                <p key={index}>{`${item.TITLE}: ${item.DESC}`}</p>
              ))}
            </div> */}
              <div className="contact-us__ways d-flex justify-content-between">
                <div className="contact-us__form">
                  <div className="title">联系信息</div>
                  <div className="ways_list">
                    {CONTACT_US_LIST.map((item, index) => (
                      <div key={index}>
                        <img src={item.ICON} alt="" />
                        <p>{`${item.TITLE}：${item.DESC}`}</p>
                      </div>
                    ))}
                  </div>
                  <div className="official-account">
                    <img src={FOOTER_QRCODE_IMG} alt="" />
                    <p>{CONTACT_US_WAYS_DESC}</p>
                  </div>
                  {/* <form action="">
                  <input
                    type="text"
                    name="name"
                    placeholder={NAME_PLACEHOLDER}
                    onChange={this.onChangeValue}
                  />
                  <input
                    type="number"
                    name="tel"
                    placeholder={TEL_PLACEHOLDER}
                    onChange={this.onChangeValue}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder={EMAIL_PLACEHOLDER}
                    onChange={this.onChangeValue}
                  />
                  <input
                    type="text"
                    name="companyName"
                    placeholder={COMPANY_PLACEHOLDER}
                    onChange={this.onChangeValue}
                  />
                  <textarea
                    type="text"
                    name="question"
                    placeholder={QUESTION_PLACEHOLDER}
                    onChange={this.onChangeValue}
                  />
                  <div>
                    <button type="submit" onClick={this.onSubmitFormInfo}>
                      {BUTTON_TITLE}
                    </button>
                  </div>
                </form> */}
                </div>
                <div className="contact-us__map">
                  <BaiDuMap mapClass="baiDu" defaultAddress={COMPANY_ADDRESS} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="contact-us mobile">
            <div className="contact-us__title">{CONTACT_US_TITLE}</div>
            {/* <div className="contact-us__ways">
            {CONTACT_US_LIST.map((item, index) => (
              <div key={index}>{`${item.TITLE}: ${item.DESC}`}</div>
            ))}
          </div> */}
            <div className="contact-us__form mb-container">
              <div className="contact-us__ways">
                {CONTACT_US_LIST.map((item, index) => (
                  <div key={index}>
                    <img src={item.ICON} alt="" />
                    <p>{`${item.TITLE}:${item.DESC}`}</p>
                  </div>
                ))}
              </div>
              <div className="official-account">
                <img src={FOOTER_QRCODE_IMG} alt="" />
                <p>{CONTACT_US_WAYS_DESC}</p>
              </div>
              {/* <form action="">
              <input
                type="text"
                name="name"
                placeholder={NAME_PLACEHOLDER}
                onChange={this.onChangeValue}
              />
              <input
                type="number"
                name="tel"
                placeholder={TEL_PLACEHOLDER}
                onChange={this.onChangeValue}
              />
              <input
                type="email"
                name="email"
                placeholder={EMAIL_PLACEHOLDER}
                onChange={this.onChangeValue}
              />
              <input
                type="text"
                name="companyName"
                placeholder={COMPANY_PLACEHOLDER}
                onChange={this.onChangeValue}
              />
              <textarea
                type="text"
                name="question"
                placeholder={QUESTION_PLACEHOLDER}
                onChange={this.onChangeValue}
              />
              <div className="form-submit">
                <button type="submit" onClick={this.onSubmitFormInfo}>
                  {BUTTON_TITLE}
                </button>
              </div>
            </form> */}
            </div>
            <div className="contact-us__map  mb-container">
              <BaiDuMap mapClass="baiDu" defaultAddress={COMPANY_ADDRESS} />
            </div>
          </div>
        )}
      </>
    )
  }
}
export default ContactUsPage;
