import { useMemo } from "react";
import LazyLoad from "react-lazyload";

import "./index.css";
import { CSS_CLASS } from "../../../../constant";
import { config } from 'utils/config';
const { homeImgUrl } = config;
const url = `${homeImgUrl}/crossSlideSection/`;

const {
    FLEX,
} = CSS_CLASS;

export default function () {

    const rows = useMemo(() => {
        const res = [];
        for (let i = 0; i < content.length; i++) {
            res.push([...content[i], ...content[i]]);
        }
        return res;
    }, []);

    return (
        <div className="partner-show__container">
            <LazyLoad offset={100}>
                <div className="partner-show__scroll-container">
                    {rows.map((row, index) => {
                        return (
                            <div
                                key={index}
                                className={`partner-show__scroll-row ${FLEX}`}
                                style={{
                                    left: rowXOffsets[index],
                                }}
                            >
                                {row.map((item, index) => {
                                    return (
                                        <img className="partner-show__scroll-row-item" key={index} src={item} alt="" />
                                    );
                                })}
                            </div>
                        );
                    })}
                    <div className="partner-show__description">
                        <h3>迈远方，<strong>看未来</strong></h3>
                        <p>我们与行业顶尖的合作伙伴们构建了良好的合作关系，为构建更美好的未来携手共进。</p>
                    </div>
                    <div className="partner-show__scroll-mask"></div>
                </div>
            </LazyLoad>
            <div className="partner-show__top-mask"></div>
        </div>
    );
}

// const {
//     ANC,
//     BYD,
//     CASC,
//     CASIC,
//     CNIGCL,
//     Colibri,
//     CSPG,
//     DISSONA,
//     EIKTO,
//     HL,
//     JDL,
//     Kunlun,
//     Lifun,
//     LLT,
//     SANY,
//     SG,
//     TONGYE,
// } = images;

const rowXOffsets = ["-1000px", "300px", "-916px", "352px"];

const content = [
    [`${url}Colibri.png`, `${url}CNIGCL.png`, `${url}EIKTO.png`, `${url}Lifun.png`, `${url}BYD.png`],
    [`${url}CSPG.png`, `${url}SG.png`, `${url}JDL.png`, `${url}LLT.png`, `${url}SANY.png`],
    [`${url}CASC.png`, `${url}CASIC.png`, `${url}Kunlun.png`, `${url}ANC.png`, `${url}SG.png`],
    [`${url}HL.png`, `${url}TONGYE.png`, `${url}DISSONA.png`, `${url}BYD.png`, `${url}Lifun.png`],
];