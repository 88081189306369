import React, { Component } from "react";

import { PRODUCT_SERVICES_INFO } from "constant/index";
import "components/Pc/ProductServicesComponent/ProductServicesBanner/ProductServicesBanner.css";

class ProductServicesBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 1,
    }
  }
  timer = null
  componentDidMount() {
    this.autoPlay();
  }
  autoPlay = () => {
    if (this.timer) {
      this.clearTimer()
    }
    this.timer = setTimeout(() => {
      let { currentIndex } = this.state
      const { PRODUCT_SERVICES_BANNER_LIST = [] } = PRODUCT_SERVICES_INFO
      const addNum = currentIndex + 1
      if (addNum >= PRODUCT_SERVICES_BANNER_LIST.length) {
        this.setState({
          currentIndex: 0,
        })
      } else {
        this.setState({
          currentIndex: addNum,
        })
      }
      this.autoPlay()
    }, 3000)
  }
  componentWillUnmount() {
    this.clearTimer()
  }
  clearTimer = () => {
    this.timer && clearTimeout(this.timer)
    this.timer = null
  }
  onMouseEnter = (currentIndex) => {
    this.clearTimer()
    this.setState({
      currentIndex,
    })
  }
  onMouseLeave = () => {
    this.autoPlay()
  }
  scrollToAnchor = () => {
    this.props.onClick && this.props.onClick()
  }
  render() {
    const {
      PRODUCT_SERVICES_TITLE = "",
      PRODUCT_SERVICES_TITLE_DESC = "",
      PRODUCT_SERVICES_BANNER_IMG = "",
      PRODUCT_SERVICES_BANNER_MORE_TITLE = "",
      PRODUCT_SERVICES_BANNER_LIST = [],
    } = PRODUCT_SERVICES_INFO
    const { currentIndex } = this.state
    return (
      <div className="product-services__banner full-page">
        <div className="container">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="product-services__banner-title">
              { PRODUCT_SERVICES_TITLE }
            </div>
            <div className="product-services__banner-title__desc">
              { PRODUCT_SERVICES_TITLE_DESC }
            </div>
            <div
              className="product-services__banner-content global__bg"
              style={{
                backgroundImage: `url(${PRODUCT_SERVICES_BANNER_IMG})`,
              }}
            >
              { PRODUCT_SERVICES_BANNER_LIST.map((item, index) => (
                <div
                  className="product-services__banner-content__item-position"
                  key={index}
                  onMouseEnter={() => this.onMouseEnter(index)}
                  onMouseLeave={this.onMouseLeave}
                >
                  <div className="product-services__banner-content__item">
                    <img src={item.ICON_2X} alt="" />
                    <span>{ item.CH_TITLE }</span>
                  </div>
                </div>
              ))}
              <div className="product-services__banner-content__center">
                { PRODUCT_SERVICES_BANNER_LIST.map((item, index) => (
                  <div
                    className="product-services__banner-content__center-item"
                    style={{
                      opacity: index === currentIndex ? 1 : 0,
                    }}
                    key={index}
                  >
                    <img src={item.ICON_2X} alt="" />
                    <div className="product-services__banner-content__center-info">
                      <p>{ item.CH_TITLE }</p>
                      <div>
                        <span>{ item.EN_TITLE }</span>
                        <span>{ item.TYPE }</span>
                        <span>{ item.CONTENT }</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button
              className="product-services__banner-more"
              onClick={this.scrollToAnchor}
            >
              { PRODUCT_SERVICES_BANNER_MORE_TITLE }
            </button>
          </div>
        </div>
      </div>
    )
  }
}
export default ProductServicesBanner
