import React, { Component } from "react";

import { PRODUCT_SERVICES_INFO } from "constant/index";
import "components/Mobile/HomeComponents/ProductServices/ProductServices.css";

class ProductServices extends Component {
  constructor() {
    super()
    this.state = {
      currentIndex: 0,
      list: PRODUCT_SERVICES_INFO?.PRODUCT_SERVICES_LIST || [],
      isClickProduct: false
    }
  }

  onAddCurrentIndex = () => {
    let { currentIndex, list } = this.state
    const addNum = currentIndex + 1
    if (addNum >= list.length) {
      return
    }
    this.setState({
      currentIndex: addNum,
    })
  }

  onReduceCurrentIndex = () => {
    const { currentIndex } = this.state
    const reduceNum = currentIndex - 1
    if (reduceNum < 0) {
      return
    }
    this.setState({
      currentIndex: reduceNum,
    })
  }

  changeProductClickStatus = () => {
    const { isClickProduct } = this.state
    this.setState({
      isClickProduct: !isClickProduct,
    })
  }
  render() {
    const { currentIndex, list, isClickProduct } = this.state;
    const {
      PRODUCT_SERVICES_TITLE = "",
      PRODUCT_SERVICES_GESTURE_ICON = "",
      PRODUCT_SERVICES_EN_TITLE_ICON = "",
      MOBILE_PRODUCT_SERVICES_LEFT_CLICK_ICON = "",
      MOBILE_PRODUCT_SERVICES_LEFT_ICON = "",
      MOBILE_PRODUCT_SERVICES_RIGHT_CLICK_ICON = "",
      MOBILE_PRODUCT_SERVICES_RIGHT_ICON = "",
    } = PRODUCT_SERVICES_INFO;
    const {
      CH_TITLE = "",
      EN_TITLE = "",
      DESC = "",
      SOURCE = "",
      DETAIL = "",
      URL = "",
      ACTIVE_URL = "",
    } = list[currentIndex];

    return (
      <div className="home-product-services">
        <div className="mb-container">
          <div className="home-product-services__title">
            { PRODUCT_SERVICES_TITLE }
          </div>
          <div className="home-product-services__wrapper">
            <div
              className="home-product-services__pic"
              onClick={this.changeProductClickStatus}
            >
              <div
                className="home-product-services__pic-front global__bg"
                style={{
                  backgroundImage: `url(${URL})`,
                  transform: isClickProduct ? "rotateY(180deg)" : "rotateY(0)",
                }}
              >
                <div className="home-product-services__pic-front-content">
                  <img src={PRODUCT_SERVICES_GESTURE_ICON} alt="" />
                  <h3>{ CH_TITLE }</h3>
                  <p>{ DESC }</p>
                </div>
                <div className="home-product-services__pic-front-content-cover" />
              </div>
              <div
                className="home-product-services__pic-back global__bg"
                style={{
                  background: `url(${ACTIVE_URL})`,
                  transform: isClickProduct
                    ? "rotateY(0deg)"
                    : "rotateY(-180deg)",
                }}
              >
                <div className="home-product-services__pic-back-content">
                  <h3>{ EN_TITLE }</h3>
                  <p>{ SOURCE }</p>
                </div>
                <div className="home-product-services__pic-front-content-cover" />
              </div>
            </div>
            <div className="home-product-services__pic-desc">
              <div className="home-product-services__pic-desc__ch-title">
                { CH_TITLE }
              </div>
              <div className="home-product-services__pic-desc__en-title">
                <i
                  className="global__bg"
                  style={{
                    backgroundImage: `url(${PRODUCT_SERVICES_EN_TITLE_ICON})`,
                  }}
                />
                <span>{ EN_TITLE }</span>
              </div>
              <div className="home-product-services__pic-desc__detail">
                { DETAIL }
              </div>
            </div>
            <div className="home-product-services__pic-btn">
              <div
                style={{
                  backgroundColor: currentIndex === 0 ? "#ffffff" : "#377dff",
                }}
                onClick={this.onReduceCurrentIndex}
              >
                <img
                  src={
                    currentIndex === 0
                      ? MOBILE_PRODUCT_SERVICES_LEFT_ICON
                      : MOBILE_PRODUCT_SERVICES_LEFT_CLICK_ICON
                  }
                  alt=""
                />
              </div>
              <div
                style={{
                  backgroundColor:
                    currentIndex < list.length - 1 ? "#377dff" : "#ffffff",
                }}
                onClick={this.onAddCurrentIndex}
              >
                <img
                  src={
                    currentIndex < list.length - 1
                      ? MOBILE_PRODUCT_SERVICES_RIGHT_CLICK_ICON
                      : MOBILE_PRODUCT_SERVICES_RIGHT_ICON
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ProductServices
