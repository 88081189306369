import LazyLoad from "react-lazyload";

import { CSS_CLASS } from "../../../../constant";
import { config } from "utils/config";
import "./index.css";
import useTransitionTitle from "pages/Home/hooks/useTransitionTitle";
const { imgUrl } = config;

const {
    FLEX,
} = CSS_CLASS;

export default function () {

    const {
        titleRef,
        titleStyle,
    } = useTransitionTitle();

    return (
        <div className="certificates__container">
            <div className="certificates__main">
                <h2 ref={titleRef} style={titleStyle}>资质认证，<span style={{ color: "#4299e1" }}>准没错</span></h2>
                <p>多项专业认证，确保了渐近线的专业实力。内容持续更新中......</p>
                <LazyLoad>
                    <div className="certificates__list">
                        <div className={`certificates__list-row ${FLEX}`}>
                            <div style={{ width: 265, height: 300, }} className="certificates__item">
                                {imageInfo[0].map((image, index) => {
                                    const {
                                        src,
                                        style,
                                    } = image;

                                    return (
                                        <img key={index} style={style} src={src} alt="" />
                                    );
                                })}
                                <p>深圳市专精特新中小企业</p>
                            </div>
                            <div style={{ width: 265, height: 300, }} className="certificates__item">
                                {imageInfo[1].map((image, index) => {
                                    const {
                                        src,
                                        style,
                                    } = image;

                                    return (
                                        <img key={index} style={style} src={src} alt="" />
                                    );
                                })}
                                <p>国家高新技术企业</p>
                            </div>
                            <div style={{ width: 550, height: 300, }} className="certificates__item">
                                {imageInfo[2].map((image, index) => {
                                    const {
                                        src,
                                        style,
                                    } = image;

                                    return (
                                        <img key={index} style={style} src={src} alt="" />
                                    );
                                })}
                                <p>ISO9001质量管理体系、ISO14001环境管理体系、ISO45001职业健康安全管理体系、ISO20000信息技术服务管理体系证书、ISO27001信息安全管理体系证书</p>
                            </div>
                        </div>
                        <div className={`certificates__list-row ${FLEX}`}>
                            <div style={{ width: 265, height: 300, }} className="certificates__item">
                                {imageInfo[3].map((image, index) => {
                                    const {
                                        src,
                                        style,
                                    } = image;
                                    const zIndex = imageInfo[3].length - index;

                                    return (
                                        <img key={index} style={{ ...style, zIndex }} src={src} alt="" />
                                    );
                                })}
                                <p>发明专利汇总</p>
                            </div>
                            <div style={{ width: 455, height: 300, }} className="certificates__item">
                                {imageInfo[4].map((image, index) => {
                                    const {
                                        src,
                                        style,
                                    } = image;
                                    const zIndex = imageInfo[4].length - index;

                                    return (
                                        <img key={index} style={{ ...style, zIndex }} src={src} alt="" />
                                    );
                                })}
                                <p>软著汇总</p>
                            </div>
                            <div style={{ width: 360, height: 300, }} className="certificates__item">
                                {imageInfo[5].map((image, index) => {
                                    const {
                                        src,
                                        style,
                                    } = image;
                                    const zIndex = imageInfo[5].length - index;

                                    return (
                                        <img key={index} style={{ ...style, zIndex, }} src={src} alt="" />
                                    );
                                })}
                                <p>实用新型汇总</p>
                            </div>
                        </div>
                    </div>
                </LazyLoad>
            </div>
            <div className="certificates__mask"></div>
        </div>
    );
}

const imageInfo = {
    0: [
        {
            src: imgUrl + "/website_images/newHome/certificates/company.png",
            style: {
                position: "absolute",
                top: 63,
                left: 10,
                width: 246,
                height: 161,
            },
        },
    ],
    1: [
        {
            src: imgUrl + "/website_images/newHome/certificates/highTech.png",
            style: {
                position: "absolute",
                top: 62,
                left: 10,
                width: 246,
                height: 164,
            },
        },
    ],
    2: [
        {
            src: imgUrl + "/website_images/newHome/certificates/ISO9001.png",
            style: {
                position: "absolute",
                top: 62,
                left: 10,
                width: 116,
                height: 164,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/ISO14001.png",
            style: {
                position: "absolute",
                top: 62,
                left: 114,
                width: 116,
                height: 164,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/ISO45001.png",
            style: {
                position: "absolute",
                top: 62,
                left: 217,
                width: 116,
                height: 164,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/ISO20000.png",
            style: {
                position: "absolute",
                top: 62,
                left: 321,
                width: 116,
                height: 164,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/ISO27001.png",
            style: {
                position: "absolute",
                top: 62,
                left: 424,
                width: 116,
                height: 164,
            },
        },
    ],
    3: [
        {
            src: imgUrl + "/website_images/newHome/certificates/patent1.png",
            style: {
                position: "absolute",
                top: 25,
                left: 10,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/patent2.png",
            style: {
                position: "absolute",
                top: 25,
                left: 33,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/patent1.png",
            style: {
                position: "absolute",
                top: 25,
                left: 56,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/patent2.png",
            style: {
                position: "absolute",
                top: 25,
                left: 79,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/patent1.png",
            style: {
                position: "absolute",
                top: 25,
                left: 102,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/patent2.png",
            style: {
                position: "absolute",
                top: 25,
                left: 125,
                width: 130,
                height: 186,
            },
        },
    ],
    4: [
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/1.png",
            style: {
                position: "absolute",
                top: 24,
                left: 9,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/2.png",
            style: {
                position: "absolute",
                top: 24,
                left: 31,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/3.png",
            style: {
                position: "absolute",
                top: 24,
                left: 53,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/4.png",
            style: {
                position: "absolute",
                top: 24,
                left: 75,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/5.png",
            style: {
                position: "absolute",
                top: 24,
                left: 97,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/6.png",
            style: {
                position: "absolute",
                top: 24,
                left: 119,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/7.png",
            style: {
                position: "absolute",
                top: 24,
                left: 141,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/8.png",
            style: {
                position: "absolute",
                top: 24,
                left: 163,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/1.png",
            style: {
                position: "absolute",
                top: 24,
                left: 185,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/2.png",
            style: {
                position: "absolute",
                top: 24,
                left: 207,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/3.png",
            style: {
                position: "absolute",
                top: 24,
                left: 229,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/4.png",
            style: {
                position: "absolute",
                top: 24,
                left: 251,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/5.png",
            style: {
                position: "absolute",
                top: 24,
                left: 273,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/6.png",
            style: {
                position: "absolute",
                top: 24,
                left: 295,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/softwareWork/7.png",
            style: {
                position: "absolute",
                top: 24,
                left: 317,
                width: 130,
                height: 186,
            },
        },
    ],
    5: [
        {
            src: imgUrl + "/website_images/newHome/certificates/practicalPatent/1.png",
            style: {
                position: "absolute",
                top: 24,
                left: 10,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/practicalPatent/2.png",
            style: {
                position: "absolute",
                top: 24,
                left: 40,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/practicalPatent/3.png",
            style: {
                position: "absolute",
                top: 24,
                left: 70,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/practicalPatent/4.png",
            style: {
                position: "absolute",
                top: 24,
                left: 100,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/practicalPatent/1.png",
            style: {
                position: "absolute",
                top: 24,
                left: 130,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/practicalPatent/2.png",
            style: {
                position: "absolute",
                top: 24,
                left: 160,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/practicalPatent/3.png",
            style: {
                position: "absolute",
                top: 24,
                left: 190,
                width: 130,
                height: 186,
            },
        },
        {
            src: imgUrl + "/website_images/newHome/certificates/practicalPatent/4.png",
            style: {
                position: "absolute",
                top: 24,
                left: 220,
                width: 130,
                height: 186,
            },
        },
    ],
};
