import React, { useEffect, useRef } from "react";

import "components/Pc/ProductServicesComponent/ProductServiceDetail/ProductServiceDetail.css";

const ProductServiceDetail = (props) => {
  const firstProductRef = useRef();

  useEffect(() => {
    props.getFirstProductionRef && props.getFirstProductionRef(firstProductRef);
  }, [props])

  const {
    item: {
      CH_TITLE = "",
      EN_TITLE = "",
      DESC = "",
      DETAIL = "",
      URL_2X = "",
      SOURCE = "",
    },
    index = 0,
  } = props
  const orderStyle =
    index % 2 === 0
      ? {
          leftOrder: 1,
          rightOrder: 2,
        }
      : {
          leftOrder: 2,
          rightOrder: 1,
        }
  return (
    <div
      className="d-flex justify-content-center align-items-center product-service__detail-content"
      ref={firstProductRef}
    >
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="product-service__detail-info"
            style={{
              order: orderStyle.leftOrder,
            }}
          >
            <div className="product-service__detail-ch__title">{ CH_TITLE }</div>
            <div className="product-service__detail-en__title">{ EN_TITLE }</div>
            <div className="product-service__detail-desc">{ DESC }</div>
            <div className="product-service__detail-centent">{ DETAIL }</div>
          </div>
          <div
            className="product-service__detail-pic__info global__bg"
            style={{
              backgroundImage: `url(${URL_2X})`,
              order: orderStyle.rightOrder,
            }}
          >
            <div className="product-service__detail-pic_content-title">
              { EN_TITLE }
            </div>
            <div className="product-service__detail-pic_content-title__desc">
              { SOURCE }
            </div>
            <div className="product-service__detail-pic_cover" />
            {/* <div
                className="product-service__detail-pic__list d-flex flex-column"
                style={index % 2 === 0 ? { right: "32px" } : { left: "32px" }}
              >
                <span />
                <span />
                <span />
              </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProductServiceDetail
