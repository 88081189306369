import React from "react";
import { Link } from "react-router-dom";

import {
  COMPANY_PUBLIC_INFO,
  FOOTER_INFO
} from "constant";
import "components/Mobile/Footer/Footer.css";

const Footer = () => {

  const { FOOTER_LOGO_IMG = "" } = COMPANY_PUBLIC_INFO;

  const CompanyInfo = () => {
    const {
      COMPANY_INFO: { TITLE = "", LIST = [] },
    } = FOOTER_INFO;

    return (
      <div className="footer-base__wrapper-item">
        <dl>
          <dt>{ TITLE }</dt>
          { LIST.map((item, index) => (
            <dd key={index}>
              <Link to={item.LINK_TO}>{ item.LINK_TITLE }</Link>
            </dd>
          ))}
        </dl>
      </div>
    )
  }

  const CompanyProduct = () => {
    const {
      COMPANY_PRODUCT: { TITLE = "", LIST = [] },
    } = FOOTER_INFO;
    return (
      <div className="footer-base__wrapper-item">
        <dl>
          <dt>{ TITLE }</dt>
          { LIST.map((item, index) => (
            <dd key={index}>
              <Link to={item.LINK_TO}>{ item.LINK_TITLE }</Link>
            </dd>
          ))}
        </dl>
      </div>
    )
  }

  const ContactUs = () => {
    const {
      CONTACT_US: { TITLE = "", LIST = [] },
    } = FOOTER_INFO;
    return (
      <div className="top-contact__content">
        <dl>
          <dt>{ TITLE }</dt>
          { LIST.map((item, index) => (
            <dd key={index}>
              <img src={item.ICON} alt="" />
              <div>
                <p>{ `${item.TITLE}：` }</p>
                <p>{ item.DESC }</p>
              </div>
            </dd>
          ))}
        </dl>
      </div>
    )
  }

  const WechatAttention = () => {
    const {
      WE_CHAT_INFO: { TITLE = "", SUB_TITLE = "" },
    } = FOOTER_INFO;
    const { FOOTER_QRCODE_IMG = "" } = COMPANY_PUBLIC_INFO;

    return (
      <div className="top-contact__qrcode">
        <div className="top-contact__qrcode-title">{ TITLE }</div>
        <div className="top-contact__qrcode-desc">{ SUB_TITLE }</div>
        <div className="top-contact__qrcode-detail">
          <img
            className="top-contact__qrcode-detail-pic"
            src={FOOTER_QRCODE_IMG}
            alt=""
          />
          {/* <div className="top-contact__qrcode-pic__list">
            {LIST.map((item, index) => (
              <img src={item} key={index} alt="" />
            ))}
          </div> */}
        </div>
      </div>
    )
  }

  const CopyRight = () => {
    const {
      COPY_RIGHT_INFO: {
        COPYRIGHT_SIGN = "",
        COPYRIGHT_WEBSITE_RECORD_NUMBER = "",
        COPYRIGHT_WEBSITE_REGISTRATION_NAME = "",
        COPYRIGHT_WEBSITE_REGISTRATION_NUMBER = "",
      },
    } = FOOTER_INFO;

    const { COMPANY_NAME = "" } = COMPANY_PUBLIC_INFO;
    return (
      <>
        <p>{ `${COPYRIGHT_SIGN}${COMPANY_NAME}` }</p>
        <p>
          <span>{ COPYRIGHT_WEBSITE_RECORD_NUMBER }</span>
          <span>{ COPYRIGHT_WEBSITE_REGISTRATION_NAME }</span>
          <span>{ COPYRIGHT_WEBSITE_REGISTRATION_NUMBER }</span>
        </p>
      </>
    )
  }

  return (
    <div className="footer-root">
      <div className="top">
        <div className="top-base">
          <div className="footer-logo">
            <img src={FOOTER_LOGO_IMG} alt="" />
          </div>
          <div className="footer-base__wrapper">
            <CompanyInfo />
            <CompanyProduct />
          </div>
        </div>
        <div className="top-contact">
          <ContactUs />
          <WechatAttention />
        </div>
      </div>
      <div className="bottom">
        <CopyRight />
      </div>
    </div>
  )
}

export default Footer
