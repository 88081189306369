import React, { Component } from "react";
import { Link } from "react-router-dom";

import { HOME_INFO } from "constant";
import "components/Mobile/HomeComponents/HomeBanner/HomeBanner.css";

class HomeBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      list: HOME_INFO.HOME_SWIPER_LIST || [],
      startX: 0,
      moveX: 0
    }
  }

  autoPlayTimer = null;

  componentDidMount() {
    this.autoPlay()
  }

  componentWillUnmount() {
    this.clearAutoPlayTimer()
  }

  autoPlay = () => {
    if (this.autoPlayTimer) {
      this.clearAutoPlayTimer()
    }
    this.autoPlayTimer = setTimeout(() => {
      let { currentIndex, list } = this.state
      currentIndex += 1
      if (currentIndex >= list.length) {
        currentIndex = 0
      }
      this.setState({
        currentIndex,
      })
      this.autoPlay()
    }, 3000)
  }

  clearAutoPlayTimer = () => {
    this.autoPlayTimer && clearTimeout(this.autoPlayTimer)
    this.autoPlayTimer = null
  }

  onChangeIndex = (currentIndex) => {
    this.clearAutoPlayTimer()
    this.setState({
      currentIndex,
    },() => {
      this.autoPlay()
    })
  }
  onTouchstart = (e) => {
    this.clearAutoPlayTimer();
    const clientX = e.touches[0].clientX;
    this.slideWidth = e.touches[0].target.clientWidth
    this.setState({
      startX: clientX,
    })
  }
  
  onTouchMove = (e) => {
    const clientX = e.touches[0].clientX;
    const { startX } = this.state;
    const moveX = clientX - startX;
    this.setState({
      moveX
    })
   
  }
  onTouchEnd = () => {
    const { currentIndex,moveX, list } = this.state;
    if(moveX > 0 && moveX > this.slideWidth / 4){
      let reduceNum = currentIndex - 1;
      if(reduceNum < 0) reduceNum = list.length - 1;
      this.setState({
        currentIndex: reduceNum
      })
    } else if (moveX < 0 && moveX < -this.slideWidth / 4) {
      let addNum = currentIndex + 1;
      if(addNum >= list.length) addNum = 0;
      this.setState({
        currentIndex: addNum
      })
    }
    this.setState({
      startX: 0,
      moveX: 0
    },() => {
      this.autoPlay()
    })
  }
  

  render() {
    const { currentIndex, list } = this.state;
    const {
      CH_TITLE = "",
      EN_TITLE = "",
      BTN_TITLE = "",
      MOBILE_BACKGROUND_URL = "",
      URL = "",
    } = list[currentIndex];
    return (
      <div className="home-banner">
        <div className="home-banner__swiper">
          <div
            className="homer-banner__swiper-slide global__bg"
            style={{
              backgroundImage: `url(${MOBILE_BACKGROUND_URL})`,
            }}
            onTouchStart={this.onTouchstart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
          >
            <div
              className="home-banner__swiper-slide__ch-title"
              style={{
                color:
                  currentIndex === 0
                    ? "rgba(255,255,255,0.85)"
                    : "rgba(0, 0, 0, 0.85)",
              }}
            >
              { CH_TITLE }
            </div>
            <div
              className="home-banner__swiper-slide__en-title"
              style={{
                color:
                  currentIndex === 0
                    ? "rgba(255,255,255,0.65)"
                    : "rgba(0, 0, 0, 0.65)",
              }}
            >
              { EN_TITLE }
            </div>
            <Link className="home-banner__swiper-slide__more" to={URL}>
              { BTN_TITLE }
            </Link>
          </div>
          <div className="homer-banner__swiper-indicator">
            { list.map((_, index) => (
              <span
                key={index}
                onClick={() => this.onChangeIndex(index)}
                className={
                  currentIndex === index
                    ? "banner__swiper-indicator__active"
                    : ""
                }
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}
export default HomeBanner
