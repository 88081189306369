import { useEffect, useRef, useState } from "react";

import { config } from "utils/config";
import { CSS_CLASS } from "../../../../constant";
import "./index.css";
const { imgUrl } = config;

const {
    FLEX,
    ALIGN_CENTER,
    JUSTIFY_CENTER,
} = CSS_CLASS;

const gradientRadius = 300;

export default function () {

    const maskRef = useRef(null);
    const animationFrameId = useRef(null);
    const [gradientStyle, setGradientStyle] = useState({
        background: "#f8fbff",
    });
    useEffect(() => {
        function handleMouseMove(event) {
            const { offsetX, offsetY } = event;
            animationFrameId.current = requestAnimationFrame(() => {
                setGradientStyle({
                    background: `
                        radial-gradient(circle at ${offsetX}px ${offsetY}px, rgba(248, 251, 255, 0) 0%, rgba(248, 251, 255, 0.25) ${gradientRadius * 0.15}px, rgba(248, 251, 255, 0.5) ${gradientRadius * 0.4}px, rgba(248, 251, 255, 0.75) ${gradientRadius * 0.65}px,rgba(248, 251, 255, 1) ${gradientRadius}px)
                    `,
                });
            });
        }
        function handleMouseLeave() {
            setGradientStyle({
                background: "#f8fbff",
            });
        }
        maskRef.current?.addEventListener("mousemove", handleMouseMove);
        maskRef.current?.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            maskRef.current?.removeEventListener("mousemove", handleMouseMove);
            maskRef.current?.removeEventListener("mouseleave", handleMouseLeave);
            cancelAnimationFrame(animationFrameId.current);
        };
    }, []);

    return (
        <div className={`service-range__container`}>
            <div style={{ width: 1124, margin: "0 auto", }}>
                <h3>核心业务范围</h3>
                <h4>渐近线专注为您提供专业、优质的服务</h4>
                <div className="service-range__card-list-container">
                    {cardItems.map(item => {
                        const {
                            icon,
                            title,
                            content,
                        } = item;

                        return (
                            <div className="service-range__card" key={title}>
                                <div className={`service-range__card-icon-container ${FLEX} ${ALIGN_CENTER} ${JUSTIFY_CENTER}`}>
                                    <img src={icon} alt="" />
                                </div>
                                <div className="service-range__card-title">{title}</div>
                                <div className="service-range__card-content">{content}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div ref={maskRef} className="service-range__mask">
                <div className="service-range__mask-gradient" style={gradientStyle}></div>
            </div>
        </div>
    );
}

const cardItems = [
    {
        icon: imgUrl + "/website_images/newHome/sectionTwo/1.svg",
        title: "新厂建设",
        content: "渐近线的AIS仿真物理引擎能够为您测试建设风险，协调知识信息，安全高效加速新工厂落地",
    },
    {
        icon: imgUrl + "/website_images/newHome/sectionTwo/2.svg",
        title: "旧厂改造",
        content: "渐近线专注提供旧系统升级改造兼容，为您高效梳理软硬件协同更新配套方案",
    },
    {
        icon: imgUrl + "/website_images/newHome/sectionTwo/3.svg",
        title: "规划仿真",
        content: "渐近线致力提供“快、准、稳”的项目规划方案，确保模拟仿真低误差、沟通交流零障碍",
    },
    {
        icon: imgUrl + "/website_images/newHome/sectionTwo/4.svg",
        title: "建设部署",
        content: "渐近线的自研引擎能够驱动生产系统快速建设部署、敏捷变更需求，为您缩短整体项目周期",
    },
    {
        icon: imgUrl + "/website_images/newHome/sectionTwo/5.svg",
        title: "生产运营",
        content: "渐近线能为您打破数据层与实物层的壁垒，塑造软件柔性生产与硬件连续生产的体系化运营平台",
    },
    {
        icon: imgUrl + "/website_images/newHome/sectionTwo/6.svg",
        title: "定制业务",
        content: "面对复杂调度流程，渐近线也能提供看得见，摸得着的可感知智能制造方案整体落地服务",
    },
];
