import React, { useState } from "react";

import "components/Mobile/JobsComponents/JobList/JobList.css";
import { JOBS_INFO } from "constant";

const JobList = () => {

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);

  const {
    JOBS_LIST_INFO: {
      JOBS_TITLE = "",
      JOBS_TITLE_DESC = "",
      // JOBS_SEARCH_NAME = "",
      // JOBS_SEARCH_PLACEHOLDER = "",
      // JOBS_TYPE_PLACEHOLDER = "",
      JOBS_LIST = [],
      JOBS_TYPE_SELECT_ICON = "",
    },
  } = JOBS_INFO;

  const changeIndex = (currentIndex) => {
    setCurrentIndex(currentIndex)
    setIsClicked(!isClicked)
  }
  return (
    <div className="jobs-list__wrapper">
      <div className="mb-container">
        <div className="jobs-list__title">{ JOBS_TITLE }</div>
        <div className="jobs-list__desc">{ JOBS_TITLE_DESC }</div>
        {/* <div className="jobs-list__search">
          <form action="" className="form">
            <input type="text" placeholder={JOBS_SEARCH_PLACEHOLDER}></input>
            <select>
              <option value="all">{JOBS_TYPE_PLACEHOLDER}</option>
              {JOBS_LIST.map((item, index) => (
                <option value={item} key={index}>
                  {item.NAME}
                </option>
              ))}
            </select>
            <button type="button">
              <span>{JOBS_SEARCH_NAME}</span>
            </button>
          </form>
        </div> */}
        <div className="jobs-box">
          { JOBS_LIST.map((item, index) => (
            <div
              key={index}
              className={`jobs-item ${
                currentIndex === index && isClicked ? "jobs-item__active" : ""
              }`}
            >
              <div className="jobs-item-name">{item.NAME}</div>
              <div className="jobs-item-detail">
                <span>{ item.ADDRESS }</span>
                <span>{ item.DEPARTMENT }</span>
                <span>{ item.WAY }</span>
              </div>
              <div
                className="jobs-item_action"
                onClick={() => changeIndex(index)}
              >
                <span>{ item.ACTION }</span>
                <i
                  className="global__bg"
                  style={{
                    backgroundImage: `url(${JOBS_TYPE_SELECT_ICON})`,
                    transform:
                      currentIndex === index && isClicked
                        ? "rotateZ(180deg)"
                        : "rotateZ(0deg)",
                  }}
                />
                <ul className="jobs-item_action-select">
                  { item?.ACTION_LIST.map((job, v) => (
                    <li
                      key={v}
                      style={{
                        height:
                          currentIndex === index && isClicked ? "0.85rem" : 0,
                      }}
                    >
                      <a
                        href={job.LINK}
                        target="_blank"
                        rel="noopener  noreferrer"
                      >
                        { job.TITLE }
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        {/* <Link className="jobs-more" to={MOBILE_JOBS_MORE_TITLE_URL}>
          {MOBILE_JOBS_MORE_TITLE}
        </Link> */}
      </div>
    </div>
  )
}
export default JobList
