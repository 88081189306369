import { CSS_CLASS } from "../../../../constant";
import * as images from "../../../../assets/images/bottomInfo";
import "./index.css";

const {
    FLEX,
    ALIGN_CENTER,
    JUSTIFY_CENTER,
} = CSS_CLASS;

export default function () {

    const {
        title,
        items,
        lastItem: {
            label,
            labelClassName,
            imgSrc,
            imgClassName,
        },
    } = rightDataSource;

    return (
        <div className={`bottom-info__container ${FLEX} ${JUSTIFY_CENTER}`}>
            <div style={{ width: 268, }}>
                <img src={images.asymptoteIcon} alt="渐近线" />
                <ul className="bottom-info__left-item-list">
                    <li>深圳市南山区粤海街道高新区社区科苑南路3170号留学生创业大厦一期810</li>
                    <li className={`${FLEX} ${ALIGN_CENTER}`}>
                        <img style={{ marginRight: 12, }} src={images.mailboxIcon} alt="" />
                        邮箱：service@asymptote.cloud
                    </li>
                    <li className={`${FLEX} ${ALIGN_CENTER}`}>
                        <img style={{ marginRight: 12, }} src={images.phoneIcon} alt="" />
                        电话：18138824738
                    </li>
                </ul>
            </div>
            {dataSource.map(data => {
                const {
                    title,
                    items,
                } = data;

                return (
                    <div className="bottom-info__center-list" key={title}>
                        <h3 className="bottom-info__list-item-title">{title}</h3>
                        <ul>
                            {items.map(item => {
                                const {
                                    label,
                                    href,
                                } = item;
                                return (
                                    <li key={label}>
                                        <a href={href}>{label}</a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })}
            <div className="bottom-info__right-list">
                <h3 className="bottom-info__list-item-title">{title}</h3>
                <ul>
                    {items.map(item => {
                        const {
                            label,
                            href,
                        } = item;
                        return (
                            <li key={label}>
                                <a href={href}>{label}</a>
                            </li>
                        );
                    })}
                </ul>
                <div>
                    <div className={labelClassName}>{label}</div>
                    <img className={imgClassName} src={imgSrc} alt="" />
                </div>
            </div>
        </div>
    );
}

const dataSource = [
    {
        title: "关于渐近线",
        items: [
            {
                label: "发展历程",
                href: "#/",
            },
            {
                label: "研发创新",
                href: "#/",
            },
            {
                label: "全国布局",
                href: "#/",
            },
            {
                label: "员工成长",
                href: "#/",
            },
            {
                label: "加入我们",
                href: "#/",
            },
        ],
    },
    {
        title: "产品服务",
        items: [
            {
                label: "WMS智能仓储管理系统",
                href: "#/",
            },
            {
                label: "MES生产执行管控系统",
                href: "#/",
            },
            {
                label: "WCS智慧仓储控制系统",
                href: "#/",
            },
            {
                label: "IS & DT 工业仿真孪生平台",
                href: "#/",
            },
            {
                label: "定制化业务",
                href: "#/",
            },
        ],
    },
    {
        title: "解决方案",
        items: [
            {
                label: "新能源锂电",
                href: "#/",
            },
            {
                label: "航天航空",
                href: "#/",
            },
            {
                label: "电子半导体",
                href: "#/",
            },
            {
                label: "机械装备",
                href: "#/",
            },
            {
                label: "电力电网",
                href: "#/",
            },
            {
                label: "军工兵器",
                href: "#/",
            },
        ],
    },
];

const rightDataSource = {
    title: "咨询中心",
    items: [
        {
            label: "新闻动态",
            href: "#/",
        },
        {
            label: "项目成果",
            href: "#/",
        },
    ],
    lastItem: {
        label: "微信扫码关注“渐近线”",
        labelClassName: "bottom-info__right-list-qrcode-label",
        imgSrc: images.qrCode,
        imgClassName: "bottom-info__right-list-qrcode-main",
    },
};
