import React from "react";

import { PRODUCT_SERVICES_INFO } from "constant";
import PanelTitle from "components/Pc/PanelTitle/PanelTitle";
import "components/Pc/ProductServicesComponent/Partnership/Partnership.css";

const Partnership = () => {

  const {
    PARTNERSHIP_INFO: {
      PARTNERSHIP_TITLE = "",
      PARTNERSHIP_IMG = "",
      PARTNERSHIP_LIST = [],
    },
  } = PRODUCT_SERVICES_INFO;
  
  return (
    <div className="product-services__partnership">
      <div className="container">
        <PanelTitle
          title={PARTNERSHIP_TITLE}
          style={{
            fontWeight: 500,
            textAlign: "center",
            marginBottom: "20.5px",
          }}
        />
        <div className="product-services__partnership-content d-flex justify-content-between">
          <div className="product-services__partnership-list">
            { PARTNERSHIP_LIST.map((item, index) => (
              <div className="product-services__partnership-item" key={index}>
                <div className="product-services__partnership-item__title">
                  { item.TITLE }
                </div>
                <div className="product-services__partnership-item__desc">
                  { item.DESC }
                </div>
              </div>
            ))}
          </div>
          <div className="product-services__partnership-pic">
            <img src={PARTNERSHIP_IMG} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Partnership;
