import React, { Component } from 'react';

import PcProductServicesBanner from 'components/Pc/ProductServicesComponent/ProductServicesBanner/ProductServicesBanner';
import PcProductServiceDetail from 'components/Pc/ProductServicesComponent/ProductServiceDetail/ProductServiceDetail';
import PcPartnership from 'components/Pc/ProductServicesComponent/Partnership/Partnership';
import MobileProductServicesBanner from 'components/Mobile/ProductServicesComponent/ProductServicesBanner/ProductServicesBanner';
import MobileProductServiceDetail from 'components/Mobile/ProductServicesComponent/ProductServiceDetail/ProductServiceDetail';
import MobileProductServiceSupports from 'components/Mobile/ProductServicesComponent/ProductServiceSupports/ProductServiceSupports';
import { PRODUCT_SERVICES_INFO } from 'constant/index';
import 'pages/ProductServicesPage/ProductServicesPage.css';

class ProductServicesPage extends Component {
  onViewAllJobs = () => {
    if (this.productRef) {
      const top = this.productRef.current?.getBoundingClientRect().top;
      const pageYOffset = window.pageYOffset;
      const offset = top + pageYOffset / 2; // 滚轮的偏移量 加上 元素距离顶部距离
      window.scroll({
        top: offset,
        behavior: 'smooth'
      });
    }
  };

  render() {
    const { PRODUCT_SERVICES_LIST = [] } = PRODUCT_SERVICES_INFO;
    const { mobileDevice } = this.props;
    return (
      <div className="product-services__page">
        {!mobileDevice ? (
          <>
            <PcProductServicesBanner onClick={this.onViewAllJobs} />
            {PRODUCT_SERVICES_LIST.map((item, index) => (
              <div
                className={`product-service__bg ${index === PRODUCT_SERVICES_LIST.length - 1 ? 'last' : ''}`}
                key={index}
              >
                <PcProductServiceDetail
                  getFirstProductionRef={index === 0 ? r => (this.productRef = r) : null}
                  item={item}
                  index={index}
                />
              </div>
            ))}
            <PcPartnership />
          </>
        ) : (
          <>
            <MobileProductServicesBanner />
            {PRODUCT_SERVICES_LIST.map((item, index) => (
              <MobileProductServiceDetail item={item} key={index} />
            ))}
            <MobileProductServiceSupports />
          </>
        )}
      </div>
    );
  }
}
export default ProductServicesPage;
