import React from "react";

import {
  JOBS_INFO,
  COMPANY_HR_EMAIL
} from "constant";
import "components/Pc/JobsComponents/JoinUs/JoinUs.css";

const JoinUs = () => {

  const {
    JOBS_JOIN_US_TITLE = "",
    JOBS_JOIN_US_DESC = "",
    JOBS_JOIN_US_CONTACT_SUB_TITLE = "",
    JOBS_JOIN_US_COPY_ICON = "",
    JOBS_JOIN_US_FOLLOW = "",
    JOBS_JOIN_US_LIST = [],
  } = JOBS_INFO;

  const onCopy = () => {
    var copyDOM = document.getElementById("info")
    var range = document.createRange()
    window.getSelection().removeAllRanges() // 清除已有的
    range.selectNode(copyDOM) // 选中要复制的
    window.getSelection().addRange(range) // 执行选中元素
    var successful = document.execCommand("copy") // 执行 copy 操作
    if (successful) {
      alert("复制成功")
    } else {
      alert("复制失败")
    }
    // 移除选中的元素
    window.getSelection().removeAllRanges()
  }
  
  return (
    <div className="jobs-page__join">
      <div className="jobs-page__join-content">
        <div className="jobs-page__join-title">{ JOBS_JOIN_US_TITLE }</div>
        <div className="jobs-page__join-desc">{ JOBS_JOIN_US_DESC }</div>
        <div className="jobs-page__join-contact">
          <span>
            { JOBS_JOIN_US_CONTACT_SUB_TITLE }
            <span id="info">{ COMPANY_HR_EMAIL }</span>
          </span>
          <i
            onClick={onCopy}
            style={{
              backgroundImage: `url(${JOBS_JOIN_US_COPY_ICON})`,
            }}
            className="global__bg"
          ></i>
        </div>
        <div className="jobs-page__join-follow">{ JOBS_JOIN_US_FOLLOW }</div>
        <div className="jobs-page__join-list d-flex justify-content-center">
          { JOBS_JOIN_US_LIST.map((item, index) => (
            <div
              className="jobs-page__join-item d-flex align-items-center"
              key={index}
            >
              <img src={item.URL} alt="" />
              <div className="d-flex flex-column justify-content-between">
                <div className="jobs-page__join-item-name">{ item.TITLE }</div>
                <div className="jobs-page__join-item-desc paragraph_limit ">
                  { item.DESC }
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default JoinUs
